export const portfolioInfo = [
  {
    id: 1,
    title: "Дом 104м2",
    mainFoto:
      "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%91.%20%D0%9A%D0%B0%D0%B1%D0%B0%D0%BD%D1%8B/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F-%D0%BA%D1%83%D1%85%D0%BD%D1%8F-%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80/6_Post%20%281%29.jpg",
    otherFotos: [
      "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%91.%20%D0%9A%D0%B0%D0%B1%D0%B0%D0%BD%D1%8B/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F-%D0%BA%D1%83%D1%85%D0%BD%D1%8F-%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80/1_Post%20%282%29.jpg",
      "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%91.%20%D0%9A%D0%B0%D0%B1%D0%B0%D0%BD%D1%8B/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F-%D0%BA%D1%83%D1%85%D0%BD%D1%8F-%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80/2_Post%20%281%29.jpg",
      "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%91.%20%D0%9A%D0%B0%D0%B1%D0%B0%D0%BD%D1%8B/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F-%D0%BA%D1%83%D1%85%D0%BD%D1%8F-%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80/3_Post%20%281%29.jpg",
      "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%91.%20%D0%9A%D0%B0%D0%B1%D0%B0%D0%BD%D1%8B/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F-%D0%BA%D1%83%D1%85%D0%BD%D1%8F-%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80/4_Post%20%281%29.jpg",
      "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%91.%20%D0%9A%D0%B0%D0%B1%D0%B0%D0%BD%D1%8B/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F-%D0%BA%D1%83%D1%85%D0%BD%D1%8F-%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80/5_Post%20%282%29.jpg",
      "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%91.%20%D0%9A%D0%B0%D0%B1%D0%B0%D0%BD%D1%8B/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F-%D0%BA%D1%83%D1%85%D0%BD%D1%8F-%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80/6_Post%20%281%29.jpg",
      "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%91.%20%D0%9A%D0%B0%D0%B1%D0%B0%D0%BD%D1%8B/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F-%D0%BA%D1%83%D1%85%D0%BD%D1%8F-%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80/7_Post.jpg",
      "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%91.%20%D0%9A%D0%B0%D0%B1%D0%B0%D0%BD%D1%8B/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F-%D0%BA%D1%83%D1%85%D0%BD%D1%8F-%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80/9_Post%20%281%29.jpg",
      "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%91.%20%D0%9A%D0%B0%D0%B1%D0%B0%D0%BD%D1%8B/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F-%D0%BA%D1%83%D1%85%D0%BD%D1%8F-%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80/10_Post.jpg",
      "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%91.%20%D0%9A%D0%B0%D0%B1%D0%B0%D0%BD%D1%8B/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F-%D0%BA%D1%83%D1%85%D0%BD%D1%8F-%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80/11_Post%20%281%29.jpg",
      "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%91.%20%D0%9A%D0%B0%D0%B1%D0%B0%D0%BD%D1%8B/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F-%D0%BA%D1%83%D1%85%D0%BD%D1%8F-%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80/12_Post.jpg",
      "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%91.%20%D0%9A%D0%B0%D0%B1%D0%B0%D0%BD%D1%8B/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F-%D0%BA%D1%83%D1%85%D0%BD%D1%8F-%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80/80000_Post.jpg",
      "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%91.%20%D0%9A%D0%B0%D0%B1%D0%B0%D0%BD%D1%8B/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F-%D0%BA%D1%83%D1%85%D0%BD%D1%8F-%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80/130000_Post.jpg",
    ],
    informationAbot: "Гостиная",
  },
  {
    id: 2,
    title: "3 Богатыря",
    mainFoto:
      "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%203%20%D0%91%D0%BE%D0%B3%D0%B0%D1%82%D1%8B%D1%80%D1%8F/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F/1_Post%20%281%29.jpg",
    otherFotos: [
      "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%203%20%D0%91%D0%BE%D0%B3%D0%B0%D1%82%D1%8B%D1%80%D1%8F/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F/2_Post.jpg",
      "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%203%20%D0%91%D0%BE%D0%B3%D0%B0%D1%82%D1%8B%D1%80%D1%8F/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F/4_Post.jpg",
      "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%203%20%D0%91%D0%BE%D0%B3%D0%B0%D1%82%D1%8B%D1%80%D1%8F/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F%20%D0%B8%20%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80/7_Post.jpg",
      "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%203%20%D0%91%D0%BE%D0%B3%D0%B0%D1%82%D1%8B%D1%80%D1%8F/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F%20%D0%B8%20%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80/9_Post.jpg",
    ],
    informationAbot: "Любая информация",
  },

  {
    id: 4,
    title: "Даурская",
    mainFoto:
      "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%202%D0%94%D0%B0%D1%83%D1%80%D1%81%D0%BA%D0%B0%D1%8F/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F%201%20%281%29.jpg",
    otherFotos: [
      "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%202%D0%94%D0%B0%D1%83%D1%80%D1%81%D0%BA%D0%B0%D1%8F/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F%201%20%281%29.jpg",
      "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%202%D0%94%D0%B0%D1%83%D1%80%D1%81%D0%BA%D0%B0%D1%8F/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F%201%20%282%29.jpg",
      "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%202%D0%94%D0%B0%D1%83%D1%80%D1%81%D0%BA%D0%B0%D1%8F/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F%201%20%283%29.jpg",
      "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%202%D0%94%D0%B0%D1%83%D1%80%D1%81%D0%BA%D0%B0%D1%8F/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F%201%20%285%29.jpg",
    ],
    informationAbot: "Любая информация",
  },
  {
    id: 5,
    title: "3 Богатыря",
    mainFoto:
      "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%203%20%D0%91%D0%BE%D0%B3%D0%B0%D1%82%D1%8B%D1%80%D1%8F/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F/1_Post%20%281%29.jpg",
    otherFotos: [
      "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%203%20%D0%91%D0%BE%D0%B3%D0%B0%D1%82%D1%8B%D1%80%D1%8F/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F/2_Post.jpg",
      "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%203%20%D0%91%D0%BE%D0%B3%D0%B0%D1%82%D1%8B%D1%80%D1%8F/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F/4_Post.jpg",
      "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%203%20%D0%91%D0%BE%D0%B3%D0%B0%D1%82%D1%8B%D1%80%D1%8F/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F%20%D0%B8%20%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80/7_Post.jpg",
      "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%203%20%D0%91%D0%BE%D0%B3%D0%B0%D1%82%D1%8B%D1%80%D1%8F/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F%20%D0%B8%20%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80/9_Post.jpg",
    ],
    informationAbot: "Любая информация",
  },
  {
    id: 6,
    title: "Даурская",
    mainFoto:
      "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%202%D0%94%D0%B0%D1%83%D1%80%D1%81%D0%BA%D0%B0%D1%8F/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F%201%20%281%29.jpg",
    otherFotos: [
      "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%202%D0%94%D0%B0%D1%83%D1%80%D1%81%D0%BA%D0%B0%D1%8F/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F%201%20%281%29.jpg",
      "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%202%D0%94%D0%B0%D1%83%D1%80%D1%81%D0%BA%D0%B0%D1%8F/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F%201%20%282%29.jpg",
      "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%202%D0%94%D0%B0%D1%83%D1%80%D1%81%D0%BA%D0%B0%D1%8F/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F%201%20%283%29.jpg",
      "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%202%D0%94%D0%B0%D1%83%D1%80%D1%81%D0%BA%D0%B0%D1%8F/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F%201%20%285%29.jpg",
    ],
    informationAbot: "Любая информация",
  },
  {
    id: 7,
    title: "3 Богатыря",
    mainFoto:
      "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%203%20%D0%91%D0%BE%D0%B3%D0%B0%D1%82%D1%8B%D1%80%D1%8F/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F/1_Post%20%281%29.jpg",
    otherFotos: [
      "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%203%20%D0%91%D0%BE%D0%B3%D0%B0%D1%82%D1%8B%D1%80%D1%8F/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F/2_Post.jpg",
      "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%203%20%D0%91%D0%BE%D0%B3%D0%B0%D1%82%D1%8B%D1%80%D1%8F/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F/4_Post.jpg",
      "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%203%20%D0%91%D0%BE%D0%B3%D0%B0%D1%82%D1%8B%D1%80%D1%8F/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F%20%D0%B8%20%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80/7_Post.jpg",
      "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%203%20%D0%91%D0%BE%D0%B3%D0%B0%D1%82%D1%8B%D1%80%D1%8F/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F%20%D0%B8%20%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80/9_Post.jpg",
    ],
    informationAbot: "Любая информация",
  },
];

export const portfolioInfo2 = [
  {
    id: 1,
    title: "Дом 104м2",
    mainFoto:
      "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%91.%20%D0%9A%D0%B0%D0%B1%D0%B0%D0%BD%D1%8B/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F-%D0%BA%D1%83%D1%85%D0%BD%D1%8F-%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80/6_Post%20%281%29.jpg",
    otherFotos: [
      {
        roomName: "Гостиная-кухня-коридор",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%91.%20%D0%9A%D0%B0%D0%B1%D0%B0%D0%BD%D1%8B/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F-%D0%BA%D1%83%D1%85%D0%BD%D1%8F-%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80/1_Post%20%282%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%91.%20%D0%9A%D0%B0%D0%B1%D0%B0%D0%BD%D1%8B/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F-%D0%BA%D1%83%D1%85%D0%BD%D1%8F-%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80/2_Post%20%281%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%91.%20%D0%9A%D0%B0%D0%B1%D0%B0%D0%BD%D1%8B/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F-%D0%BA%D1%83%D1%85%D0%BD%D1%8F-%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80/3_Post%20%281%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%91.%20%D0%9A%D0%B0%D0%B1%D0%B0%D0%BD%D1%8B/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F-%D0%BA%D1%83%D1%85%D0%BD%D1%8F-%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80/4_Post%20%281%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%91.%20%D0%9A%D0%B0%D0%B1%D0%B0%D0%BD%D1%8B/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F-%D0%BA%D1%83%D1%85%D0%BD%D1%8F-%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80/5_Post%20%282%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%91.%20%D0%9A%D0%B0%D0%B1%D0%B0%D0%BD%D1%8B/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F-%D0%BA%D1%83%D1%85%D0%BD%D1%8F-%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80/6_Post%20%281%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%91.%20%D0%9A%D0%B0%D0%B1%D0%B0%D0%BD%D1%8B/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F-%D0%BA%D1%83%D1%85%D0%BD%D1%8F-%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80/7_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%91.%20%D0%9A%D0%B0%D0%B1%D0%B0%D0%BD%D1%8B/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F-%D0%BA%D1%83%D1%85%D0%BD%D1%8F-%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80/9_Post%20%281%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%91.%20%D0%9A%D0%B0%D0%B1%D0%B0%D0%BD%D1%8B/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F-%D0%BA%D1%83%D1%85%D0%BD%D1%8F-%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80/10_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%91.%20%D0%9A%D0%B0%D0%B1%D0%B0%D0%BD%D1%8B/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F-%D0%BA%D1%83%D1%85%D0%BD%D1%8F-%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80/11_Post%20%281%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%91.%20%D0%9A%D0%B0%D0%B1%D0%B0%D0%BD%D1%8B/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F-%D0%BA%D1%83%D1%85%D0%BD%D1%8F-%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80/12_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%91.%20%D0%9A%D0%B0%D0%B1%D0%B0%D0%BD%D1%8B/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F-%D0%BA%D1%83%D1%85%D0%BD%D1%8F-%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80/80000_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%91.%20%D0%9A%D0%B0%D0%B1%D0%B0%D0%BD%D1%8B/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F-%D0%BA%D1%83%D1%85%D0%BD%D1%8F-%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80/130000_Post.jpg",
        ],
      },
      {
        roomName: "Детская",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%91.%20%D0%9A%D0%B0%D0%B1%D0%B0%D0%BD%D1%8B/%D0%94%D0%B5%D1%82%D1%81%D0%BA%D0%B0%D1%8F/1.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%91.%20%D0%9A%D0%B0%D0%B1%D0%B0%D0%BD%D1%8B/%D0%94%D0%B5%D1%82%D1%81%D0%BA%D0%B0%D1%8F/2.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%91.%20%D0%9A%D0%B0%D0%B1%D0%B0%D0%BD%D1%8B/%D0%94%D0%B5%D1%82%D1%81%D0%BA%D0%B0%D1%8F/3.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%91.%20%D0%9A%D0%B0%D0%B1%D0%B0%D0%BD%D1%8B/%D0%94%D0%B5%D1%82%D1%81%D0%BA%D0%B0%D1%8F/4.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%91.%20%D0%9A%D0%B0%D0%B1%D0%B0%D0%BD%D1%8B/%D0%94%D0%B5%D1%82%D1%81%D0%BA%D0%B0%D1%8F/5.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%91.%20%D0%9A%D0%B0%D0%B1%D0%B0%D0%BD%D1%8B/%D0%94%D0%B5%D1%82%D1%81%D0%BA%D0%B0%D1%8F/6.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%91.%20%D0%9A%D0%B0%D0%B1%D0%B0%D0%BD%D1%8B/%D0%94%D0%B5%D1%82%D1%81%D0%BA%D0%B0%D1%8F/7.jpg",
        ],
      },
      {
        roomName: "Спальня",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%91.%20%D0%9A%D0%B0%D0%B1%D0%B0%D0%BD%D1%8B/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F/1.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%91.%20%D0%9A%D0%B0%D0%B1%D0%B0%D0%BD%D1%8B/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F/2.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%91.%20%D0%9A%D0%B0%D0%B1%D0%B0%D0%BD%D1%8B/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F/3.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%91.%20%D0%9A%D0%B0%D0%B1%D0%B0%D0%BD%D1%8B/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F/4.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%91.%20%D0%9A%D0%B0%D0%B1%D0%B0%D0%BD%D1%8B/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F/5.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%91.%20%D0%9A%D0%B0%D0%B1%D0%B0%D0%BD%D1%8B/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F/6.jpg",
        ],
      },
      {
        roomName: "Мастер СУ",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%91.%20%D0%9A%D0%B0%D0%B1%D0%B0%D0%BD%D1%8B/%D0%9C%D0%B0%D1%81%D1%82%D0%B5%D1%80%20%D0%A1%D0%A3/1_Post%20%282%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%91.%20%D0%9A%D0%B0%D0%B1%D0%B0%D0%BD%D1%8B/%D0%9C%D0%B0%D1%81%D1%82%D0%B5%D1%80%20%D0%A1%D0%A3/2_Post%20%281%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%91.%20%D0%9A%D0%B0%D0%B1%D0%B0%D0%BD%D1%8B/%D0%9C%D0%B0%D1%81%D1%82%D0%B5%D1%80%20%D0%A1%D0%A3/3_Post%20%281%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%91.%20%D0%9A%D0%B0%D0%B1%D0%B0%D0%BD%D1%8B/%D0%9C%D0%B0%D1%81%D1%82%D0%B5%D1%80%20%D0%A1%D0%A3/4_Post%20%281%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%91.%20%D0%9A%D0%B0%D0%B1%D0%B0%D0%BD%D1%8B/%D0%9C%D0%B0%D1%81%D1%82%D0%B5%D1%80%20%D0%A1%D0%A3/5_Post%20%282%29.jpg",
        ],
      },
      {
        roomName: "Кабинет",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%91.%20%D0%9A%D0%B0%D0%B1%D0%B0%D0%BD%D1%8B/%D0%9A%D0%B0%D0%B1%D0%B8%D0%BD%D0%B5%D1%82/1.jpg",

          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%91.%20%D0%9A%D0%B0%D0%B1%D0%B0%D0%BD%D1%8B/%D0%9A%D0%B0%D0%B1%D0%B8%D0%BD%D0%B5%D1%82/2.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%91.%20%D0%9A%D0%B0%D0%B1%D0%B0%D0%BD%D1%8B/%D0%9A%D0%B0%D0%B1%D0%B8%D0%BD%D0%B5%D1%82/3.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%91.%20%D0%9A%D0%B0%D0%B1%D0%B0%D0%BD%D1%8B/%D0%9A%D0%B0%D0%B1%D0%B8%D0%BD%D0%B5%D1%82/4.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%91.%20%D0%9A%D0%B0%D0%B1%D0%B0%D0%BD%D1%8B/%D0%9A%D0%B0%D0%B1%D0%B8%D0%BD%D0%B5%D1%82/5.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%91.%20%D0%9A%D0%B0%D0%B1%D0%B0%D0%BD%D1%8B/%D0%9A%D0%B0%D0%B1%D0%B8%D0%BD%D0%B5%D1%82/6.jpg",
        ],
      },
      {
        roomName: "Гардеробная",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%91.%20%D0%9A%D0%B0%D0%B1%D0%B0%D0%BD%D1%8B/%D0%93%D0%B0%D1%80%D0%B4%D0%B5%D1%80%D0%BE%D0%B1%D0%BD%D0%B0%D1%8F/1_Post%20%282%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%91.%20%D0%9A%D0%B0%D0%B1%D0%B0%D0%BD%D1%8B/%D0%93%D0%B0%D1%80%D0%B4%D0%B5%D1%80%D0%BE%D0%B1%D0%BD%D0%B0%D1%8F/2_Post%20%281%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%91.%20%D0%9A%D0%B0%D0%B1%D0%B0%D0%BD%D1%8B/%D0%93%D0%B0%D1%80%D0%B4%D0%B5%D1%80%D0%BE%D0%B1%D0%BD%D0%B0%D1%8F/3_Post%20%281%29.jpg",
        ],
      },
      {
        roomName: "Гостевой СУ",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%91.%20%D0%9A%D0%B0%D0%B1%D0%B0%D0%BD%D1%8B/%D0%93%D0%BE%D1%81%D1%82%D0%B5%D0%B2%D0%BE%D0%B9%20%D0%A1%D0%A3/1_Post%20%282%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%91.%20%D0%9A%D0%B0%D0%B1%D0%B0%D0%BD%D1%8B/%D0%93%D0%BE%D1%81%D1%82%D0%B5%D0%B2%D0%BE%D0%B9%20%D0%A1%D0%A3/2_Post%20%281%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%91.%20%D0%9A%D0%B0%D0%B1%D0%B0%D0%BD%D1%8B/%D0%93%D0%BE%D1%81%D1%82%D0%B5%D0%B2%D0%BE%D0%B9%20%D0%A1%D0%A3/3_Post%20%281%29.jpg",
        ],
      },
      {
        roomName: "Котельная",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%91.%20%D0%9A%D0%B0%D0%B1%D0%B0%D0%BD%D1%8B/%D0%9A%D0%BE%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D0%B0%D1%8F/1_Post%20%282%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%91.%20%D0%9A%D0%B0%D0%B1%D0%B0%D0%BD%D1%8B/%D0%9A%D0%BE%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D0%B0%D1%8F/2_Post%20%281%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%91.%20%D0%9A%D0%B0%D0%B1%D0%B0%D0%BD%D1%8B/%D0%9A%D0%BE%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D0%B0%D1%8F/3_Post%20%281%29.jpg",
        ],
      },
      {
        roomName: "Прихожая",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%91.%20%D0%9A%D0%B0%D0%B1%D0%B0%D0%BD%D1%8B/%D0%9F%D1%80%D0%B8%D1%85%D0%BE%D0%B6%D0%B0%D1%8F/1_Post%20%282%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%91.%20%D0%9A%D0%B0%D0%B1%D0%B0%D0%BD%D1%8B/%D0%9F%D1%80%D0%B8%D1%85%D0%BE%D0%B6%D0%B0%D1%8F/2_Post%20%281%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%91.%20%D0%9A%D0%B0%D0%B1%D0%B0%D0%BD%D1%8B/%D0%9F%D1%80%D0%B8%D1%85%D0%BE%D0%B6%D0%B0%D1%8F/3_Post%20%281%29.jpg",
        ],
      },
    ],
  },

  {
    id: 2,
    title: "Квартира 63м2",
    mainFoto:
      "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%95%D0%B2%D1%80%D0%B0%D0%B7%D0%B8%D1%8F/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F/1_Post.jpg",
    otherFotos: [
      {
        roomName: "Спальня",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%95%D0%B2%D1%80%D0%B0%D0%B7%D0%B8%D1%8F/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F/1_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%95%D0%B2%D1%80%D0%B0%D0%B7%D0%B8%D1%8F/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F/2_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%95%D0%B2%D1%80%D0%B0%D0%B7%D0%B8%D1%8F/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F/3_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%95%D0%B2%D1%80%D0%B0%D0%B7%D0%B8%D1%8F/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F/4_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%95%D0%B2%D1%80%D0%B0%D0%B7%D0%B8%D1%8F/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F/5_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%95%D0%B2%D1%80%D0%B0%D0%B7%D0%B8%D1%8F/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F/6_Post.jpg",
        ],
      },
      {
        roomName: "Детская",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%95%D0%B2%D1%80%D0%B0%D0%B7%D0%B8%D1%8F/%D0%94%D0%B5%D1%82%D1%81%D0%BA%D0%B0%D1%8F/1_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%95%D0%B2%D1%80%D0%B0%D0%B7%D0%B8%D1%8F/%D0%94%D0%B5%D1%82%D1%81%D0%BA%D0%B0%D1%8F/2_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%95%D0%B2%D1%80%D0%B0%D0%B7%D0%B8%D1%8F/%D0%94%D0%B5%D1%82%D1%81%D0%BA%D0%B0%D1%8F/3_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%95%D0%B2%D1%80%D0%B0%D0%B7%D0%B8%D1%8F/%D0%94%D0%B5%D1%82%D1%81%D0%BA%D0%B0%D1%8F/4_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%95%D0%B2%D1%80%D0%B0%D0%B7%D0%B8%D1%8F/%D0%94%D0%B5%D1%82%D1%81%D0%BA%D0%B0%D1%8F/5_Post.jpg",
        ],
      },

      {
        roomName: "Ванная",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%95%D0%B2%D1%80%D0%B0%D0%B7%D0%B8%D1%8F/%D0%92%D0%B0%D0%BD%D0%BD%D0%B0%D1%8F/1_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%95%D0%B2%D1%80%D0%B0%D0%B7%D0%B8%D1%8F/%D0%92%D0%B0%D0%BD%D0%BD%D0%B0%D1%8F/2_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%95%D0%B2%D1%80%D0%B0%D0%B7%D0%B8%D1%8F/%D0%92%D0%B0%D0%BD%D0%BD%D0%B0%D1%8F/3_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%95%D0%B2%D1%80%D0%B0%D0%B7%D0%B8%D1%8F/%D0%92%D0%B0%D0%BD%D0%BD%D0%B0%D1%8F/4_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%95%D0%B2%D1%80%D0%B0%D0%B7%D0%B8%D1%8F/%D0%92%D0%B0%D0%BD%D0%BD%D0%B0%D1%8F/5_Post.jpg",
        ],
      },
      {
        roomName: "Гостиная",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%95%D0%B2%D1%80%D0%B0%D0%B7%D0%B8%D1%8F/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/1_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%95%D0%B2%D1%80%D0%B0%D0%B7%D0%B8%D1%8F/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/2_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%95%D0%B2%D1%80%D0%B0%D0%B7%D0%B8%D1%8F/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/3_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%95%D0%B2%D1%80%D0%B0%D0%B7%D0%B8%D1%8F/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/4_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%95%D0%B2%D1%80%D0%B0%D0%B7%D0%B8%D1%8F/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/5_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%95%D0%B2%D1%80%D0%B0%D0%B7%D0%B8%D1%8F/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/6_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%95%D0%B2%D1%80%D0%B0%D0%B7%D0%B8%D1%8F/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/7_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%95%D0%B2%D1%80%D0%B0%D0%B7%D0%B8%D1%8F/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/8_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%95%D0%B2%D1%80%D0%B0%D0%B7%D0%B8%D1%8F/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/9_Post.jpg",
        ],
      },
      {
        roomName: "Гардеробная",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%95%D0%B2%D1%80%D0%B0%D0%B7%D0%B8%D1%8F/%D0%93%D0%B0%D1%80%D0%B4%D0%B5%D1%80%D0%BE%D0%B1%D0%BD%D0%B0%D1%8F/1_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%95%D0%B2%D1%80%D0%B0%D0%B7%D0%B8%D1%8F/%D0%93%D0%B0%D1%80%D0%B4%D0%B5%D1%80%D0%BE%D0%B1%D0%BD%D0%B0%D1%8F/2_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%95%D0%B2%D1%80%D0%B0%D0%B7%D0%B8%D1%8F/%D0%93%D0%B0%D1%80%D0%B4%D0%B5%D1%80%D0%BE%D0%B1%D0%BD%D0%B0%D1%8F/3_Post.jpg",
        ],
      },
    ],
  },

  {
    id: 3,
    title: "Кухня-гостиная 40м2",
    mainFoto:
      "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A8%D1%83%D1%80%D1%82%D1%8B%D0%B3%D0%B8%D0%BD%D0%B0/7_Post.jpg",
    otherFotos: [
      {
        roomName: "",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A8%D1%83%D1%80%D1%82%D1%8B%D0%B3%D0%B8%D0%BD%D0%B0/1_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A8%D1%83%D1%80%D1%82%D1%8B%D0%B3%D0%B8%D0%BD%D0%B0/2_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A8%D1%83%D1%80%D1%82%D1%8B%D0%B3%D0%B8%D0%BD%D0%B0/3_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A8%D1%83%D1%80%D1%82%D1%8B%D0%B3%D0%B8%D0%BD%D0%B0/4_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A8%D1%83%D1%80%D1%82%D1%8B%D0%B3%D0%B8%D0%BD%D0%B0/5_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A8%D1%83%D1%80%D1%82%D1%8B%D0%B3%D0%B8%D0%BD%D0%B0/6_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A8%D1%83%D1%80%D1%82%D1%8B%D0%B3%D0%B8%D0%BD%D0%B0/7_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A8%D1%83%D1%80%D1%82%D1%8B%D0%B3%D0%B8%D0%BD%D0%B0/7_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A8%D1%83%D1%80%D1%82%D1%8B%D0%B3%D0%B8%D0%BD%D0%B0/8_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A8%D1%83%D1%80%D1%82%D1%8B%D0%B3%D0%B8%D0%BD%D0%B0/9_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A8%D1%83%D1%80%D1%82%D1%8B%D0%B3%D0%B8%D0%BD%D0%B0/10_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A8%D1%83%D1%80%D1%82%D1%8B%D0%B3%D0%B8%D0%BD%D0%B0/12_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A8%D1%83%D1%80%D1%82%D1%8B%D0%B3%D0%B8%D0%BD%D0%B0/13_Post.jpg",
        ],
      },
    ],
  },

  {
    id: 4,
    title: " Квартира 38м2",
    mainFoto:
      "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A7%D0%B5%D1%82%D0%B0%D0%B5%D0%B2%D0%B0/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F-%D1%81%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F/5_Post.jpg",
    otherFotos: [
      {
        roomName: "Гостиная-спальня",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A7%D0%B5%D1%82%D0%B0%D0%B5%D0%B2%D0%B0/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F-%D1%81%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F/1_Post%20%289%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A7%D0%B5%D1%82%D0%B0%D0%B5%D0%B2%D0%B0/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F-%D1%81%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F/2_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A7%D0%B5%D1%82%D0%B0%D0%B5%D0%B2%D0%B0/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F-%D1%81%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F/3_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A7%D0%B5%D1%82%D0%B0%D0%B5%D0%B2%D0%B0/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F-%D1%81%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F/4_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A7%D0%B5%D1%82%D0%B0%D0%B5%D0%B2%D0%B0/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F-%D1%81%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F/5_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A7%D0%B5%D1%82%D0%B0%D0%B5%D0%B2%D0%B0/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F-%D1%81%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F/7_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A7%D0%B5%D1%82%D0%B0%D0%B5%D0%B2%D0%B0/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F-%D1%81%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F/8_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A7%D0%B5%D1%82%D0%B0%D0%B5%D0%B2%D0%B0/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F-%D1%81%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F/60000_Post.jpg",
        ],
      },
      {
        roomName: "Кухня-коридор",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A7%D0%B5%D1%82%D0%B0%D0%B5%D0%B2%D0%B0/%D0%9A%D1%83%D1%85%D0%BD%D1%8F-%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80/1_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A7%D0%B5%D1%82%D0%B0%D0%B5%D0%B2%D0%B0/%D0%9A%D1%83%D1%85%D0%BD%D1%8F-%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80/2_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A7%D0%B5%D1%82%D0%B0%D0%B5%D0%B2%D0%B0/%D0%9A%D1%83%D1%85%D0%BD%D1%8F-%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80/3_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A7%D0%B5%D1%82%D0%B0%D0%B5%D0%B2%D0%B0/%D0%9A%D1%83%D1%85%D0%BD%D1%8F-%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80/4_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A7%D0%B5%D1%82%D0%B0%D0%B5%D0%B2%D0%B0/%D0%9A%D1%83%D1%85%D0%BD%D1%8F-%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80/5_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A7%D0%B5%D1%82%D0%B0%D0%B5%D0%B2%D0%B0/%D0%9A%D1%83%D1%85%D0%BD%D1%8F-%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80/6_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A7%D0%B5%D1%82%D0%B0%D0%B5%D0%B2%D0%B0/%D0%9A%D1%83%D1%85%D0%BD%D1%8F-%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80/7_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A7%D0%B5%D1%82%D0%B0%D0%B5%D0%B2%D0%B0/%D0%9A%D1%83%D1%85%D0%BD%D1%8F-%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80/8_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A7%D0%B5%D1%82%D0%B0%D0%B5%D0%B2%D0%B0/%D0%9A%D1%83%D1%85%D0%BD%D1%8F-%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80/9_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A7%D0%B5%D1%82%D0%B0%D0%B5%D0%B2%D0%B0/%D0%9A%D1%83%D1%85%D0%BD%D1%8F-%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80/10_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A7%D0%B5%D1%82%D0%B0%D0%B5%D0%B2%D0%B0/%D0%9A%D1%83%D1%85%D0%BD%D1%8F-%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80/17_Post.jpg",
        ],
      },
      {
        roomName: "СУ",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A7%D0%B5%D1%82%D0%B0%D0%B5%D0%B2%D0%B0/%D0%A1%D0%A3/1_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A7%D0%B5%D1%82%D0%B0%D0%B5%D0%B2%D0%B0/%D0%A1%D0%A3/2_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A7%D0%B5%D1%82%D0%B0%D0%B5%D0%B2%D0%B0/%D0%A1%D0%A3/3_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A7%D0%B5%D1%82%D0%B0%D0%B5%D0%B2%D0%B0/%D0%A1%D0%A3/4_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A7%D0%B5%D1%82%D0%B0%D0%B5%D0%B2%D0%B0/%D0%A1%D0%A3/5_Post.jpg",
        ],
      },
      {
        roomName: "Балкон",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A7%D0%B5%D1%82%D0%B0%D0%B5%D0%B2%D0%B0/%D0%91%D0%B0%D0%BB%D0%BA%D0%BE%D0%BD/2_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A7%D0%B5%D1%82%D0%B0%D0%B5%D0%B2%D0%B0/%D0%91%D0%B0%D0%BB%D0%BA%D0%BE%D0%BD/1_Post.jpg",
        ],
      },
    ],
  },
  {
    id: 5,
    title: "Банный комплекс 41м2",
    mainFoto:
      "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%91%D0%B0%D0%BD%D1%8F%20%D0%92%D0%BE%D0%BB%D0%B6%D1%81%D0%BA/%D0%9F%D0%B0%D1%80%D0%BD%D0%B0%D1%8F/1_Post.jpg",
    otherFotos: [
      {
        roomName: "Парная",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%91%D0%B0%D0%BD%D1%8F%20%D0%92%D0%BE%D0%BB%D0%B6%D1%81%D0%BA/%D0%9F%D0%B0%D1%80%D0%BD%D0%B0%D1%8F/1_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%91%D0%B0%D0%BD%D1%8F%20%D0%92%D0%BE%D0%BB%D0%B6%D1%81%D0%BA/%D0%9F%D0%B0%D1%80%D0%BD%D0%B0%D1%8F/3_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%91%D0%B0%D0%BD%D1%8F%20%D0%92%D0%BE%D0%BB%D0%B6%D1%81%D0%BA/%D0%9F%D0%B0%D1%80%D0%BD%D0%B0%D1%8F/20100_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%91%D0%B0%D0%BD%D1%8F%20%D0%92%D0%BE%D0%BB%D0%B6%D1%81%D0%BA/%D0%9F%D0%B0%D1%80%D0%BD%D0%B0%D1%8F/40100_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%91%D0%B0%D0%BD%D1%8F%20%D0%92%D0%BE%D0%BB%D0%B6%D1%81%D0%BA/%D0%9F%D0%B0%D1%80%D0%BD%D0%B0%D1%8F/50100_Post.jpg",
        ],
      },
      {
        roomName: "Комната отдыха",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%91%D0%B0%D0%BD%D1%8F%20%D0%92%D0%BE%D0%BB%D0%B6%D1%81%D0%BA/%D0%9A%D0%BE%D0%BC%D0%BD%D0%B0%D1%82%D0%B0%20%D0%BE%D1%82%D0%B4%D1%8B%D1%85%D0%B0/1_Post%20%288%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%91%D0%B0%D0%BD%D1%8F%20%D0%92%D0%BE%D0%BB%D0%B6%D1%81%D0%BA/%D0%9A%D0%BE%D0%BC%D0%BD%D0%B0%D1%82%D0%B0%20%D0%BE%D1%82%D0%B4%D1%8B%D1%85%D0%B0/2_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%91%D0%B0%D0%BD%D1%8F%20%D0%92%D0%BE%D0%BB%D0%B6%D1%81%D0%BA/%D0%9A%D0%BE%D0%BC%D0%BD%D0%B0%D1%82%D0%B0%20%D0%BE%D1%82%D0%B4%D1%8B%D1%85%D0%B0/3_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%91%D0%B0%D0%BD%D1%8F%20%D0%92%D0%BE%D0%BB%D0%B6%D1%81%D0%BA/%D0%9A%D0%BE%D0%BC%D0%BD%D0%B0%D1%82%D0%B0%20%D0%BE%D1%82%D0%B4%D1%8B%D1%85%D0%B0/4_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%91%D0%B0%D0%BD%D1%8F%20%D0%92%D0%BE%D0%BB%D0%B6%D1%81%D0%BA/%D0%9A%D0%BE%D0%BC%D0%BD%D0%B0%D1%82%D0%B0%20%D0%BE%D1%82%D0%B4%D1%8B%D1%85%D0%B0/5_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%91%D0%B0%D0%BD%D1%8F%20%D0%92%D0%BE%D0%BB%D0%B6%D1%81%D0%BA/%D0%9A%D0%BE%D0%BC%D0%BD%D0%B0%D1%82%D0%B0%20%D0%BE%D1%82%D0%B4%D1%8B%D1%85%D0%B0/6_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%91%D0%B0%D0%BD%D1%8F%20%D0%92%D0%BE%D0%BB%D0%B6%D1%81%D0%BA/%D0%9A%D0%BE%D0%BC%D0%BD%D0%B0%D1%82%D0%B0%20%D0%BE%D1%82%D0%B4%D1%8B%D1%85%D0%B0/7_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%91%D0%B0%D0%BD%D1%8F%20%D0%92%D0%BE%D0%BB%D0%B6%D1%81%D0%BA/%D0%9A%D0%BE%D0%BC%D0%BD%D0%B0%D1%82%D0%B0%20%D0%BE%D1%82%D0%B4%D1%8B%D1%85%D0%B0/8_Post.jpg",
        ],
      },
      {
        roomName: "Помывочная",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%91%D0%B0%D0%BD%D1%8F%20%D0%92%D0%BE%D0%BB%D0%B6%D1%81%D0%BA/%D0%9F%D0%BE%D0%BC%D1%8B%D0%B2%D0%BE%D1%87%D0%BD%D0%B0%D1%8F/11_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%91%D0%B0%D0%BD%D1%8F%20%D0%92%D0%BE%D0%BB%D0%B6%D1%81%D0%BA/%D0%9F%D0%BE%D0%BC%D1%8B%D0%B2%D0%BE%D1%87%D0%BD%D0%B0%D1%8F/13_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%91%D0%B0%D0%BD%D1%8F%20%D0%92%D0%BE%D0%BB%D0%B6%D1%81%D0%BA/%D0%9F%D0%BE%D0%BC%D1%8B%D0%B2%D0%BE%D1%87%D0%BD%D0%B0%D1%8F/14_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%91%D0%B0%D0%BD%D1%8F%20%D0%92%D0%BE%D0%BB%D0%B6%D1%81%D0%BA/%D0%9F%D0%BE%D0%BC%D1%8B%D0%B2%D0%BE%D1%87%D0%BD%D0%B0%D1%8F/15_Post.jpg",
        ],
      },

      {
        roomName: "Раздевалка",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%91%D0%B0%D0%BD%D1%8F%20%D0%92%D0%BE%D0%BB%D0%B6%D1%81%D0%BA/%D0%A0%D0%B0%D0%B7%D0%B4%D0%B5%D0%B2%D0%B0%D0%BB%D0%BA%D0%B0/1_Post.jpg",

          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%91%D0%B0%D0%BD%D1%8F%20%D0%92%D0%BE%D0%BB%D0%B6%D1%81%D0%BA/%D0%A0%D0%B0%D0%B7%D0%B4%D0%B5%D0%B2%D0%B0%D0%BB%D0%BA%D0%B0/2_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%91%D0%B0%D0%BD%D1%8F%20%D0%92%D0%BE%D0%BB%D0%B6%D1%81%D0%BA/%D0%A0%D0%B0%D0%B7%D0%B4%D0%B5%D0%B2%D0%B0%D0%BB%D0%BA%D0%B0/3_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%91%D0%B0%D0%BD%D1%8F%20%D0%92%D0%BE%D0%BB%D0%B6%D1%81%D0%BA/%D0%A0%D0%B0%D0%B7%D0%B4%D0%B5%D0%B2%D0%B0%D0%BB%D0%BA%D0%B0/4_Post.jpg",
        ],
      },
      {
        roomName: "СУ",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%91%D0%B0%D0%BD%D1%8F%20%D0%92%D0%BE%D0%BB%D0%B6%D1%81%D0%BA/%D0%A1%D0%A3/1_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%91%D0%B0%D0%BD%D1%8F%20%D0%92%D0%BE%D0%BB%D0%B6%D1%81%D0%BA/%D0%A1%D0%A3/2_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%91%D0%B0%D0%BD%D1%8F%20%D0%92%D0%BE%D0%BB%D0%B6%D1%81%D0%BA/%D0%A1%D0%A3/4_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%91%D0%B0%D0%BD%D1%8F%20%D0%92%D0%BE%D0%BB%D0%B6%D1%81%D0%BA/%D0%A1%D0%A3/5_Post.jpg",
        ],
      },
      {
        roomName: "Прихожая",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%91%D0%B0%D0%BD%D1%8F%20%D0%92%D0%BE%D0%BB%D0%B6%D1%81%D0%BA/%D0%9F%D1%80%D0%B8%D1%85%D0%BE%D0%B6%D0%B0%D1%8F/1_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%91%D0%B0%D0%BD%D1%8F%20%D0%92%D0%BE%D0%BB%D0%B6%D1%81%D0%BA/%D0%9F%D1%80%D0%B8%D1%85%D0%BE%D0%B6%D0%B0%D1%8F/2_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%91%D0%B0%D0%BD%D1%8F%20%D0%92%D0%BE%D0%BB%D0%B6%D1%81%D0%BA/%D0%9F%D1%80%D0%B8%D1%85%D0%BE%D0%B6%D0%B0%D1%8F/3_Post.jpg",
        ],
      },
    ],
  },
  {
    id: 6,
    title: "Квартира 40м2",
    mainFoto:
      "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9B%D0%B5%D1%82%D0%BE/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/4_Post1.jpg",
    otherFotos: [
      {
        roomName: "Гостиная",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9B%D0%B5%D1%82%D0%BE/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/1_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9B%D0%B5%D1%82%D0%BE/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/2_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9B%D0%B5%D1%82%D0%BE/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/3_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9B%D0%B5%D1%82%D0%BE/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/4_Post1.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9B%D0%B5%D1%82%D0%BE/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/5_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9B%D0%B5%D1%82%D0%BE/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/6_Post1.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9B%D0%B5%D1%82%D0%BE/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/7_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9B%D0%B5%D1%82%D0%BE/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/80000_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9B%D0%B5%D1%82%D0%BE/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/90000_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9B%D0%B5%D1%82%D0%BE/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/100000_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9B%D0%B5%D1%82%D0%BE/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/110000_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9B%D0%B5%D1%82%D0%BE/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/120000_Post.jpg",
        ],
      },
      {
        roomName: "Спальня",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9B%D0%B5%D1%82%D0%BE/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F/1_Post.jpg",

          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9B%D0%B5%D1%82%D0%BE/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F/2_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9B%D0%B5%D1%82%D0%BE/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F/3_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9B%D0%B5%D1%82%D0%BE/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F/5_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9B%D0%B5%D1%82%D0%BE/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F/6_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9B%D0%B5%D1%82%D0%BE/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F/7_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9B%D0%B5%D1%82%D0%BE/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F/11_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9B%D0%B5%D1%82%D0%BE/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F/12_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9B%D0%B5%D1%82%D0%BE/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F/13_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9B%D0%B5%D1%82%D0%BE/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F/14_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9B%D0%B5%D1%82%D0%BE/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F/17_Post.jpg",
        ],
      },
      {
        roomName: "СУ",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9B%D0%B5%D1%82%D0%BE/%D0%A1%D0%A3/1_Post%20%281%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9B%D0%B5%D1%82%D0%BE/%D0%A1%D0%A3/2_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9B%D0%B5%D1%82%D0%BE/%D0%A1%D0%A3/3_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9B%D0%B5%D1%82%D0%BE/%D0%A1%D0%A3/4_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9B%D0%B5%D1%82%D0%BE/%D0%A1%D0%A3/5_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9B%D0%B5%D1%82%D0%BE/%D0%A1%D0%A3/6_Post.jpg",
        ],
      },
    ],
  },

  {
    id: 7,
    title: "Квартира 57м2",
    mainFoto:
      "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20Sreda%20of%20life/%D0%94%D0%B5%D1%82%D1%81%D0%BA%D0%B0%D1%8F/80000_Post.jpg",
    otherFotos: [
      {
        roomName: "Коридор",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20Sreda%20of%20life/%D0%9A%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80/10000_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20Sreda%20of%20life/%D0%9A%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80/20000_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20Sreda%20of%20life/%D0%9A%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80/30000_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20Sreda%20of%20life/%D0%9A%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80/40000_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20Sreda%20of%20life/%D0%9A%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80/50000_Post.jpg",
        ],
      },
      {
        roomName: "Спальня",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20Sreda%20of%20life/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F/1_Post%20%281%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20Sreda%20of%20life/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F/2_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20Sreda%20of%20life/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F/4_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20Sreda%20of%20life/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F/5_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20Sreda%20of%20life/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F/30000_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20Sreda%20of%20life/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F/60000_Post.jpg",
        ],
      },
      {
        roomName: "Детская",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20Sreda%20of%20life/%D0%94%D0%B5%D1%82%D1%81%D0%BA%D0%B0%D1%8F/1_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20Sreda%20of%20life/%D0%94%D0%B5%D1%82%D1%81%D0%BA%D0%B0%D1%8F/4_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20Sreda%20of%20life/%D0%94%D0%B5%D1%82%D1%81%D0%BA%D0%B0%D1%8F/5_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20Sreda%20of%20life/%D0%94%D0%B5%D1%82%D1%81%D0%BA%D0%B0%D1%8F/6_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20Sreda%20of%20life/%D0%94%D0%B5%D1%82%D1%81%D0%BA%D0%B0%D1%8F/20000_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20Sreda%20of%20life/%D0%94%D0%B5%D1%82%D1%81%D0%BA%D0%B0%D1%8F/30000_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20Sreda%20of%20life/%D0%94%D0%B5%D1%82%D1%81%D0%BA%D0%B0%D1%8F/70000_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20Sreda%20of%20life/%D0%94%D0%B5%D1%82%D1%81%D0%BA%D0%B0%D1%8F/80000_Post.jpg",
        ],
      },

      {
        roomName: "Ванная",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20Sreda%20of%20life/%D0%92%D0%B0%D0%BD%D0%BD%D0%B0%D1%8F/1_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20Sreda%20of%20life/%D0%92%D0%B0%D0%BD%D0%BD%D0%B0%D1%8F/2_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20Sreda%20of%20life/%D0%92%D0%B0%D0%BD%D0%BD%D0%B0%D1%8F/3_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20Sreda%20of%20life/%D0%92%D0%B0%D0%BD%D0%BD%D0%B0%D1%8F/4_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20Sreda%20of%20life/%D0%92%D0%B0%D0%BD%D0%BD%D0%B0%D1%8F/5_Post.jpg",
        ],
      },
      {
        roomName: "Гардеробная",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20Sreda%20of%20life/%D0%93%D0%B0%D1%80%D0%B4%D0%B5%D1%80%D0%BE%D0%B1%D0%BD%D0%B0%D1%8F/1_Post%20%281%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20Sreda%20of%20life/%D0%93%D0%B0%D1%80%D0%B4%D0%B5%D1%80%D0%BE%D0%B1%D0%BD%D0%B0%D1%8F/2_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20Sreda%20of%20life/%D0%93%D0%B0%D1%80%D0%B4%D0%B5%D1%80%D0%BE%D0%B1%D0%BD%D0%B0%D1%8F/3_Post.jpg",
        ],
      },

      {
        roomName: "Кухня-гостиная",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20Sreda%20of%20life/%D0%9A%D1%83%D1%85%D0%BD%D1%8F-%D0%B3%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/1_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20Sreda%20of%20life/%D0%9A%D1%83%D1%85%D0%BD%D1%8F-%D0%B3%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/2_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20Sreda%20of%20life/%D0%9A%D1%83%D1%85%D0%BD%D1%8F-%D0%B3%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/3_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20Sreda%20of%20life/%D0%9A%D1%83%D1%85%D0%BD%D1%8F-%D0%B3%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/4_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20Sreda%20of%20life/%D0%9A%D1%83%D1%85%D0%BD%D1%8F-%D0%B3%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/50000_Post.jpg",
        ],
      },
      {
        roomName: "Балкон",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20Sreda%20of%20life/%D0%91%D0%B0%D0%BB%D0%BA%D0%BE%D0%BD/1_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20Sreda%20of%20life/%D0%91%D0%B0%D0%BB%D0%BA%D0%BE%D0%BD/2_Post.jpg",
        ],
      },
      {
        roomName: "СУ",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20Sreda%20of%20life/%D0%A1%D0%A3/1_Post%20%287%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20Sreda%20of%20life/%D0%A1%D0%A3/2_Post%20%283%29.jpg",
        ],
      },
    ],
  },
  {
    id: 8,
    title: "Дом 120м2",
    mainFoto:
      "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9A%D1%83%D1%8E%D0%BA%D0%B8/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/2_Post.jpg",
    otherFotos: [
      {
        roomName: "Гостиная",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9A%D1%83%D1%8E%D0%BA%D0%B8/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/1_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9A%D1%83%D1%8E%D0%BA%D0%B8/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/2_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9A%D1%83%D1%8E%D0%BA%D0%B8/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/3_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9A%D1%83%D1%8E%D0%BA%D0%B8/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/4_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9A%D1%83%D1%8E%D0%BA%D0%B8/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/5_Post%20%281%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9A%D1%83%D1%8E%D0%BA%D0%B8/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/8_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9A%D1%83%D1%8E%D0%BA%D0%B8/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/6_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9A%D1%83%D1%8E%D0%BA%D0%B8/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/7_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9A%D1%83%D1%8E%D0%BA%D0%B8/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/9_Post.jpg",
        ],
      },

      {
        roomName: "Кабинет",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9A%D1%83%D1%8E%D0%BA%D0%B8/%D0%9A%D0%B0%D0%B1%D0%B8%D0%BD%D0%B5%D1%82/1_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9A%D1%83%D1%8E%D0%BA%D0%B8/%D0%9A%D0%B0%D0%B1%D0%B8%D0%BD%D0%B5%D1%82/2_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9A%D1%83%D1%8E%D0%BA%D0%B8/%D0%9A%D0%B0%D0%B1%D0%B8%D0%BD%D0%B5%D1%82/3_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9A%D1%83%D1%8E%D0%BA%D0%B8/%D0%9A%D0%B0%D0%B1%D0%B8%D0%BD%D0%B5%D1%82/4_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9A%D1%83%D1%8E%D0%BA%D0%B8/%D0%9A%D0%B0%D0%B1%D0%B8%D0%BD%D0%B5%D1%82/12_Post.jpg",
        ],
      },
      {
        roomName: "Коридор",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9A%D1%83%D1%8E%D0%BA%D0%B8/%D0%9A%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80/1_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9A%D1%83%D1%8E%D0%BA%D0%B8/%D0%9A%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80/2_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9A%D1%83%D1%8E%D0%BA%D0%B8/%D0%9A%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80/3_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9A%D1%83%D1%8E%D0%BA%D0%B8/%D0%9A%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80/4_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9A%D1%83%D1%8E%D0%BA%D0%B8/%D0%9A%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80/8_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9A%D1%83%D1%8E%D0%BA%D0%B8/%D0%9A%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80/6_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9A%D1%83%D1%8E%D0%BA%D0%B8/%D0%9A%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80/7_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9A%D1%83%D1%8E%D0%BA%D0%B8/%D0%9A%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80/9_Post.jpg",
        ],
      },
      {
        roomName: "Мастерская",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9A%D1%83%D1%8E%D0%BA%D0%B8/%D0%9C%D0%B0%D1%81%D1%82%D0%B5%D1%80%D1%81%D0%BA%D0%B0%D1%8F/1_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9A%D1%83%D1%8E%D0%BA%D0%B8/%D0%9C%D0%B0%D1%81%D1%82%D0%B5%D1%80%D1%81%D0%BA%D0%B0%D1%8F/4_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9A%D1%83%D1%8E%D0%BA%D0%B8/%D0%9C%D0%B0%D1%81%D1%82%D0%B5%D1%80%D1%81%D0%BA%D0%B0%D1%8F/3_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9A%D1%83%D1%8E%D0%BA%D0%B8/%D0%9C%D0%B0%D1%81%D1%82%D0%B5%D1%80%D1%81%D0%BA%D0%B0%D1%8F/20000_Post.jpg",
        ],
      },
      {
        roomName: "Спальня",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9A%D1%83%D1%8E%D0%BA%D0%B8/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F/1_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9A%D1%83%D1%8E%D0%BA%D0%B8/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F/2_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9A%D1%83%D1%8E%D0%BA%D0%B8/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F/3_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9A%D1%83%D1%8E%D0%BA%D0%B8/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F/4_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9A%D1%83%D1%8E%D0%BA%D0%B8/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F/50000_Post.jpg",
        ],
      },
      {
        roomName: "СУ",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9A%D1%83%D1%8E%D0%BA%D0%B8/%D0%A1%D0%A3%201%20%D1%8D%D1%82%D0%B0%D0%B6/1_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9A%D1%83%D1%8E%D0%BA%D0%B8/%D0%A1%D0%A3%201%20%D1%8D%D1%82%D0%B0%D0%B6/2_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9A%D1%83%D1%8E%D0%BA%D0%B8/%D0%A1%D0%A3%201%20%D1%8D%D1%82%D0%B0%D0%B6/3_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9A%D1%83%D1%8E%D0%BA%D0%B8/%D0%A1%D0%A3%201%20%D1%8D%D1%82%D0%B0%D0%B6/4_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9A%D1%83%D1%8E%D0%BA%D0%B8/%D0%A1%D0%A3%201%20%D1%8D%D1%82%D0%B0%D0%B6/5_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9A%D1%83%D1%8E%D0%BA%D0%B8/%D0%A1%D0%A3%201%20%D1%8D%D1%82%D0%B0%D0%B6/6_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9A%D1%83%D1%8E%D0%BA%D0%B8/%D0%A1%D0%A3%201%20%D1%8D%D1%82%D0%B0%D0%B6/7_Post.jpg",
        ],
      },
      {
        roomName: "СУ",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9A%D1%83%D1%8E%D0%BA%D0%B8/%D0%A1%D0%A3%202%20%D1%8D%D1%82%D0%B0%D0%B6/1_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9A%D1%83%D1%8E%D0%BA%D0%B8/%D0%A1%D0%A3%202%20%D1%8D%D1%82%D0%B0%D0%B6/2_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9A%D1%83%D1%8E%D0%BA%D0%B8/%D0%A1%D0%A3%202%20%D1%8D%D1%82%D0%B0%D0%B6/3_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9A%D1%83%D1%8E%D0%BA%D0%B8/%D0%A1%D0%A3%202%20%D1%8D%D1%82%D0%B0%D0%B6/4_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9A%D1%83%D1%8E%D0%BA%D0%B8/%D0%A1%D0%A3%202%20%D1%8D%D1%82%D0%B0%D0%B6/6_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9A%D1%83%D1%8E%D0%BA%D0%B8/%D0%A1%D0%A3%202%20%D1%8D%D1%82%D0%B0%D0%B6/7_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9A%D1%83%D1%8E%D0%BA%D0%B8/%D0%A1%D0%A3%202%20%D1%8D%D1%82%D0%B0%D0%B6/8_Post.jpg",
        ],
      },
      {
        roomName: "Гардероб",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9A%D1%83%D1%8E%D0%BA%D0%B8/%D0%93%D0%B0%D1%80%D0%B4%D0%B5%D1%80%D0%BE%D0%B1/1_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9A%D1%83%D1%8E%D0%BA%D0%B8/%D0%93%D0%B0%D1%80%D0%B4%D0%B5%D1%80%D0%BE%D0%B1/3_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9A%D1%83%D1%8E%D0%BA%D0%B8/%D0%93%D0%B0%D1%80%D0%B4%D0%B5%D1%80%D0%BE%D0%B1/2_Post.jpg",
        ],
      },
    ],
  },

  {
    id: 9,
    title: "Квартира 59м2",
    mainFoto:
      "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A0%D0%BE%D0%B4%D0%B8%D0%BD%D0%B0/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F/1_Post%20%284%29.jpg",
    otherFotos: [
      {
        roomName: "Спальня",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A0%D0%BE%D0%B4%D0%B8%D0%BD%D0%B0/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F/1_Post%20%284%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A0%D0%BE%D0%B4%D0%B8%D0%BD%D0%B0/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F/2_Post%20%284%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A0%D0%BE%D0%B4%D0%B8%D0%BD%D0%B0/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F/3_Post%20%283%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A0%D0%BE%D0%B4%D0%B8%D0%BD%D0%B0/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F/40000_Post%20%281%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A0%D0%BE%D0%B4%D0%B8%D0%BD%D0%B0/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F/50000_Post.jpg",
        ],
      },

      {
        roomName: "Гостиная",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A0%D0%BE%D0%B4%D0%B8%D0%BD%D0%B0/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/1_Post%20%284%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A0%D0%BE%D0%B4%D0%B8%D0%BD%D0%B0/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/2_Post%20%284%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A0%D0%BE%D0%B4%D0%B8%D0%BD%D0%B0/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/4_Post%20%281%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A0%D0%BE%D0%B4%D0%B8%D0%BD%D0%B0/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/6_Post%20%282%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A0%D0%BE%D0%B4%D0%B8%D0%BD%D0%B0/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/7_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A0%D0%BE%D0%B4%D0%B8%D0%BD%D0%B0/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/8_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A0%D0%BE%D0%B4%D0%B8%D0%BD%D0%B0/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/30000_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A0%D0%BE%D0%B4%D0%B8%D0%BD%D0%B0/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/50000_Post.jpg",
        ],
      },
      {
        roomName: "Кухня, коридор",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A0%D0%BE%D0%B4%D0%B8%D0%BD%D0%B0/%D0%9A%D1%83%D1%85%D0%BD%D1%8F%2C%20%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80/1_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A0%D0%BE%D0%B4%D0%B8%D0%BD%D0%B0/%D0%9A%D1%83%D1%85%D0%BD%D1%8F%2C%20%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80/2_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A0%D0%BE%D0%B4%D0%B8%D0%BD%D0%B0/%D0%9A%D1%83%D1%85%D0%BD%D1%8F%2C%20%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80/3_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A0%D0%BE%D0%B4%D0%B8%D0%BD%D0%B0/%D0%9A%D1%83%D1%85%D0%BD%D1%8F%2C%20%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80/4_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A0%D0%BE%D0%B4%D0%B8%D0%BD%D0%B0/%D0%9A%D1%83%D1%85%D0%BD%D1%8F%2C%20%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80/10_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A0%D0%BE%D0%B4%D0%B8%D0%BD%D0%B0/%D0%9A%D1%83%D1%85%D0%BD%D1%8F%2C%20%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80/50000_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A0%D0%BE%D0%B4%D0%B8%D0%BD%D0%B0/%D0%9A%D1%83%D1%85%D0%BD%D1%8F%2C%20%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80/60000_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A0%D0%BE%D0%B4%D0%B8%D0%BD%D0%B0/%D0%9A%D1%83%D1%85%D0%BD%D1%8F%2C%20%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80/70000_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A0%D0%BE%D0%B4%D0%B8%D0%BD%D0%B0/%D0%9A%D1%83%D1%85%D0%BD%D1%8F%2C%20%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80/80000_Post.jpg",
        ],
      },
      {
        roomName: "СУ",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A0%D0%BE%D0%B4%D0%B8%D0%BD%D0%B0/%D0%A1%D0%A3/51_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A0%D0%BE%D0%B4%D0%B8%D0%BD%D0%B0/%D0%A1%D0%A3/52_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A0%D0%BE%D0%B4%D0%B8%D0%BD%D0%B0/%D0%A1%D0%A3/53_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A0%D0%BE%D0%B4%D0%B8%D0%BD%D0%B0/%D0%A1%D0%A3/54_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A0%D0%BE%D0%B4%D0%B8%D0%BD%D0%B0/%D0%A1%D0%A3/55_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A0%D0%BE%D0%B4%D0%B8%D0%BD%D0%B0/%D0%A1%D0%A3/56_Post.jpg",
        ],
      },
      {
        roomName: "СУ 2",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A0%D0%BE%D0%B4%D0%B8%D0%BD%D0%B0/%D0%A1%D0%A3%202/1_Post%20%284%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A0%D0%BE%D0%B4%D0%B8%D0%BD%D0%B0/%D0%A1%D0%A3%202/2_Post%20%284%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A0%D0%BE%D0%B4%D0%B8%D0%BD%D0%B0/%D0%A1%D0%A3%202/3_Post%20%283%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A0%D0%BE%D0%B4%D0%B8%D0%BD%D0%B0/%D0%A1%D0%A3%202/4_Post%20%281%29.jpg",
        ],
      },
      {
        roomName: "Балкон",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A0%D0%BE%D0%B4%D0%B8%D0%BD%D0%B0/%D0%91%D0%B0%D0%BB%D0%BA%D0%BE%D0%BD/1_Post%20%284%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A0%D0%BE%D0%B4%D0%B8%D0%BD%D0%B0/%D0%91%D0%B0%D0%BB%D0%BA%D0%BE%D0%BD/2_Post%20%284%29.jpg",
        ],
      },
    ],
  },

  {
    id: 10,
    title: "Дом 243м2",
    mainFoto:
      "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%92%D0%B5%D1%80%D1%85%D0%BD%D0%B8%D0%B9%20%D0%A3%D1%81%D0%BB%D0%BE%D0%BD/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/1.jpg",
    otherFotos: [
      {
        roomName: "Гостиная",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%92%D0%B5%D1%80%D1%85%D0%BD%D0%B8%D0%B9%20%D0%A3%D1%81%D0%BB%D0%BE%D0%BD/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/1.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%92%D0%B5%D1%80%D1%85%D0%BD%D0%B8%D0%B9%20%D0%A3%D1%81%D0%BB%D0%BE%D0%BD/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/2.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%92%D0%B5%D1%80%D1%85%D0%BD%D0%B8%D0%B9%20%D0%A3%D1%81%D0%BB%D0%BE%D0%BD/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/3.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%92%D0%B5%D1%80%D1%85%D0%BD%D0%B8%D0%B9%20%D0%A3%D1%81%D0%BB%D0%BE%D0%BD/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/4.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%92%D0%B5%D1%80%D1%85%D0%BD%D0%B8%D0%B9%20%D0%A3%D1%81%D0%BB%D0%BE%D0%BD/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/5.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%92%D0%B5%D1%80%D1%85%D0%BD%D0%B8%D0%B9%20%D0%A3%D1%81%D0%BB%D0%BE%D0%BD/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/6.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%92%D0%B5%D1%80%D1%85%D0%BD%D0%B8%D0%B9%20%D0%A3%D1%81%D0%BB%D0%BE%D0%BD/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/7.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%92%D0%B5%D1%80%D1%85%D0%BD%D0%B8%D0%B9%20%D0%A3%D1%81%D0%BB%D0%BE%D0%BD/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/8.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%92%D0%B5%D1%80%D1%85%D0%BD%D0%B8%D0%B9%20%D0%A3%D1%81%D0%BB%D0%BE%D0%BD/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/9.jpg",
        ],
      },
      {
        roomName: "Гардеробная + кладовая",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%92%D0%B5%D1%80%D1%85%D0%BD%D0%B8%D0%B9%20%D0%A3%D1%81%D0%BB%D0%BE%D0%BD/%D0%93%D0%B0%D1%80%D0%B4%D0%B5%D1%80%D0%BE%D0%B1%D0%BD%D0%B0%D1%8F%20%2B%20%20%D0%BA%D0%BB%D0%B0%D0%B4%D0%BE%D0%B2%D0%B0%D1%8F/1.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%92%D0%B5%D1%80%D1%85%D0%BD%D0%B8%D0%B9%20%D0%A3%D1%81%D0%BB%D0%BE%D0%BD/%D0%93%D0%B0%D1%80%D0%B4%D0%B5%D1%80%D0%BE%D0%B1%D0%BD%D0%B0%D1%8F%20%2B%20%20%D0%BA%D0%BB%D0%B0%D0%B4%D0%BE%D0%B2%D0%B0%D1%8F/2.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%92%D0%B5%D1%80%D1%85%D0%BD%D0%B8%D0%B9%20%D0%A3%D1%81%D0%BB%D0%BE%D0%BD/%D0%93%D0%B0%D1%80%D0%B4%D0%B5%D1%80%D0%BE%D0%B1%D0%BD%D0%B0%D1%8F%20%2B%20%20%D0%BA%D0%BB%D0%B0%D0%B4%D0%BE%D0%B2%D0%B0%D1%8F/3.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%92%D0%B5%D1%80%D1%85%D0%BD%D0%B8%D0%B9%20%D0%A3%D1%81%D0%BB%D0%BE%D0%BD/%D0%93%D0%B0%D1%80%D0%B4%D0%B5%D1%80%D0%BE%D0%B1%D0%BD%D0%B0%D1%8F%20%2B%20%20%D0%BA%D0%BB%D0%B0%D0%B4%D0%BE%D0%B2%D0%B0%D1%8F/4.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%92%D0%B5%D1%80%D1%85%D0%BD%D0%B8%D0%B9%20%D0%A3%D1%81%D0%BB%D0%BE%D0%BD/%D0%93%D0%B0%D1%80%D0%B4%D0%B5%D1%80%D0%BE%D0%B1%D0%BD%D0%B0%D1%8F%20%2B%20%20%D0%BA%D0%BB%D0%B0%D0%B4%D0%BE%D0%B2%D0%B0%D1%8F/5.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%92%D0%B5%D1%80%D1%85%D0%BD%D0%B8%D0%B9%20%D0%A3%D1%81%D0%BB%D0%BE%D0%BD/%D0%93%D0%B0%D1%80%D0%B4%D0%B5%D1%80%D0%BE%D0%B1%D0%BD%D0%B0%D1%8F%20%2B%20%20%D0%BA%D0%BB%D0%B0%D0%B4%D0%BE%D0%B2%D0%B0%D1%8F/6.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%92%D0%B5%D1%80%D1%85%D0%BD%D0%B8%D0%B9%20%D0%A3%D1%81%D0%BB%D0%BE%D0%BD/%D0%93%D0%B0%D1%80%D0%B4%D0%B5%D1%80%D0%BE%D0%B1%D0%BD%D0%B0%D1%8F%20%2B%20%20%D0%BA%D0%BB%D0%B0%D0%B4%D0%BE%D0%B2%D0%B0%D1%8F/7.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%92%D0%B5%D1%80%D1%85%D0%BD%D0%B8%D0%B9%20%D0%A3%D1%81%D0%BB%D0%BE%D0%BD/%D0%93%D0%B0%D1%80%D0%B4%D0%B5%D1%80%D0%BE%D0%B1%D0%BD%D0%B0%D1%8F%20%2B%20%20%D0%BA%D0%BB%D0%B0%D0%B4%D0%BE%D0%B2%D0%B0%D1%8F/8.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%92%D0%B5%D1%80%D1%85%D0%BD%D0%B8%D0%B9%20%D0%A3%D1%81%D0%BB%D0%BE%D0%BD/%D0%93%D0%B0%D1%80%D0%B4%D0%B5%D1%80%D0%BE%D0%B1%D0%BD%D0%B0%D1%8F%20%2B%20%20%D0%BA%D0%BB%D0%B0%D0%B4%D0%BE%D0%B2%D0%B0%D1%8F/9.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%92%D0%B5%D1%80%D1%85%D0%BD%D0%B8%D0%B9%20%D0%A3%D1%81%D0%BB%D0%BE%D0%BD/%D0%93%D0%B0%D1%80%D0%B4%D0%B5%D1%80%D0%BE%D0%B1%D0%BD%D0%B0%D1%8F%20%2B%20%20%D0%BA%D0%BB%D0%B0%D0%B4%D0%BE%D0%B2%D0%B0%D1%8F/10.jpg",
        ],
      },
      {
        roomName: "Гостевая",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%92%D0%B5%D1%80%D1%85%D0%BD%D0%B8%D0%B9%20%D0%A3%D1%81%D0%BB%D0%BE%D0%BD/%D0%93%D0%BE%D1%81%D1%82%D0%B5%D0%B2%D0%B0%D1%8F/1.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%92%D0%B5%D1%80%D1%85%D0%BD%D0%B8%D0%B9%20%D0%A3%D1%81%D0%BB%D0%BE%D0%BD/%D0%93%D0%BE%D1%81%D1%82%D0%B5%D0%B2%D0%B0%D1%8F/2.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%92%D0%B5%D1%80%D1%85%D0%BD%D0%B8%D0%B9%20%D0%A3%D1%81%D0%BB%D0%BE%D0%BD/%D0%93%D0%BE%D1%81%D1%82%D0%B5%D0%B2%D0%B0%D1%8F/3.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%92%D0%B5%D1%80%D1%85%D0%BD%D0%B8%D0%B9%20%D0%A3%D1%81%D0%BB%D0%BE%D0%BD/%D0%93%D0%BE%D1%81%D1%82%D0%B5%D0%B2%D0%B0%D1%8F/4.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%92%D0%B5%D1%80%D1%85%D0%BD%D0%B8%D0%B9%20%D0%A3%D1%81%D0%BB%D0%BE%D0%BD/%D0%93%D0%BE%D1%81%D1%82%D0%B5%D0%B2%D0%B0%D1%8F/5.jpg",
        ],
      },
      {
        roomName: "Гостевой СУ",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%92%D0%B5%D1%80%D1%85%D0%BD%D0%B8%D0%B9%20%D0%A3%D1%81%D0%BB%D0%BE%D0%BD/%D0%93%D0%BE%D1%81%D1%82%D0%B5%D0%B2%D0%BE%D0%B9%20%D0%A1%D0%A3/1.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%92%D0%B5%D1%80%D1%85%D0%BD%D0%B8%D0%B9%20%D0%A3%D1%81%D0%BB%D0%BE%D0%BD/%D0%93%D0%BE%D1%81%D1%82%D0%B5%D0%B2%D0%BE%D0%B9%20%D0%A1%D0%A3/2.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%92%D0%B5%D1%80%D1%85%D0%BD%D0%B8%D0%B9%20%D0%A3%D1%81%D0%BB%D0%BE%D0%BD/%D0%93%D0%BE%D1%81%D1%82%D0%B5%D0%B2%D0%BE%D0%B9%20%D0%A1%D0%A3/3.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%92%D0%B5%D1%80%D1%85%D0%BD%D0%B8%D0%B9%20%D0%A3%D1%81%D0%BB%D0%BE%D0%BD/%D0%93%D0%BE%D1%81%D1%82%D0%B5%D0%B2%D0%BE%D0%B9%20%D0%A1%D0%A3/4.jpg",
        ],
      },
    ],
  },

  {
    id: 11,
    title: "Квартира 75м2",
    mainFoto:
      "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9B%D0%B5%D0%B3%D0%B5%D0%BD%D0%B4%D0%B0/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/1.jpg",
    otherFotos: [
      {
        roomName: "Гостиная",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9B%D0%B5%D0%B3%D0%B5%D0%BD%D0%B4%D0%B0/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/1.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9B%D0%B5%D0%B3%D0%B5%D0%BD%D0%B4%D0%B0/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/2.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9B%D0%B5%D0%B3%D0%B5%D0%BD%D0%B4%D0%B0/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/3.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9B%D0%B5%D0%B3%D0%B5%D0%BD%D0%B4%D0%B0/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/4.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9B%D0%B5%D0%B3%D0%B5%D0%BD%D0%B4%D0%B0/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/5.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9B%D0%B5%D0%B3%D0%B5%D0%BD%D0%B4%D0%B0/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/6.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9B%D0%B5%D0%B3%D0%B5%D0%BD%D0%B4%D0%B0/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/7.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9B%D0%B5%D0%B3%D0%B5%D0%BD%D0%B4%D0%B0/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/8.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9B%D0%B5%D0%B3%D0%B5%D0%BD%D0%B4%D0%B0/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/9.jpg",
        ],
      },
      {
        roomName: "Спальня",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9B%D0%B5%D0%B3%D0%B5%D0%BD%D0%B4%D0%B0/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F/1.JPG",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9B%D0%B5%D0%B3%D0%B5%D0%BD%D0%B4%D0%B0/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F/2.JPG",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9B%D0%B5%D0%B3%D0%B5%D0%BD%D0%B4%D0%B0/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F/3.JPG",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9B%D0%B5%D0%B3%D0%B5%D0%BD%D0%B4%D0%B0/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F/4.JPG",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9B%D0%B5%D0%B3%D0%B5%D0%BD%D0%B4%D0%B0/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F/5.JPG",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9B%D0%B5%D0%B3%D0%B5%D0%BD%D0%B4%D0%B0/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F/6.JPG",
        ],
      },
      {
        roomName: "Гардеробная",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9B%D0%B5%D0%B3%D0%B5%D0%BD%D0%B4%D0%B0/%D0%93%D0%B0%D1%80%D0%B4%D0%B5%D1%80%D0%BE%D0%B1%D0%BD%D0%B0%D1%8F/1.JPG",

          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9B%D0%B5%D0%B3%D0%B5%D0%BD%D0%B4%D0%B0/%D0%93%D0%B0%D1%80%D0%B4%D0%B5%D1%80%D0%BE%D0%B1%D0%BD%D0%B0%D1%8F/2.JPG",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9B%D0%B5%D0%B3%D0%B5%D0%BD%D0%B4%D0%B0/%D0%93%D0%B0%D1%80%D0%B4%D0%B5%D1%80%D0%BE%D0%B1%D0%BD%D0%B0%D1%8F/3.JPG",
        ],
      },
      {
        roomName: "Балкон",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9B%D0%B5%D0%B3%D0%B5%D0%BD%D0%B4%D0%B0/%D0%91%D0%B0%D0%BB%D0%BA%D0%BE%D0%BD/1_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9B%D0%B5%D0%B3%D0%B5%D0%BD%D0%B4%D0%B0/%D0%91%D0%B0%D0%BB%D0%BA%D0%BE%D0%BD/2_Post%20%281%29.jpg",
        ],
      },
      {
        roomName: "Ванная",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9B%D0%B5%D0%B3%D0%B5%D0%BD%D0%B4%D0%B0/%D0%92%D0%B0%D0%BD%D0%BD%D0%B0%D1%8F/1_Post%20%282%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9B%D0%B5%D0%B3%D0%B5%D0%BD%D0%B4%D0%B0/%D0%92%D0%B0%D0%BD%D0%BD%D0%B0%D1%8F/2_Post%20%282%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9B%D0%B5%D0%B3%D0%B5%D0%BD%D0%B4%D0%B0/%D0%92%D0%B0%D0%BD%D0%BD%D0%B0%D1%8F/3_Post%20%281%29.jpg",
        ],
      },
    ],
  },

  {
    id: 12,
    title: "Квартира 67м2",
    mainFoto:
      "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A1%D0%B0%D0%B2%D0%B8%D0%BD%20%D0%A5%D0%B0%D1%83%D1%81/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F-%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80/5_Post.jpg",
    otherFotos: [
      {
        roomName: "Гостиная",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A1%D0%B0%D0%B2%D0%B8%D0%BD%20%D0%A5%D0%B0%D1%83%D1%81/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F-%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80/1_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A1%D0%B0%D0%B2%D0%B8%D0%BD%20%D0%A5%D0%B0%D1%83%D1%81/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F-%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80/2_Post%20%281%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A1%D0%B0%D0%B2%D0%B8%D0%BD%20%D0%A5%D0%B0%D1%83%D1%81/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F-%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80/3_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A1%D0%B0%D0%B2%D0%B8%D0%BD%20%D0%A5%D0%B0%D1%83%D1%81/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F-%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80/4_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A1%D0%B0%D0%B2%D0%B8%D0%BD%20%D0%A5%D0%B0%D1%83%D1%81/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F-%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80/11_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A1%D0%B0%D0%B2%D0%B8%D0%BD%20%D0%A5%D0%B0%D1%83%D1%81/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F-%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80/60000_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A1%D0%B0%D0%B2%D0%B8%D0%BD%20%D0%A5%D0%B0%D1%83%D1%81/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F-%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80/70000_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A1%D0%B0%D0%B2%D0%B8%D0%BD%20%D0%A5%D0%B0%D1%83%D1%81/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F-%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80/80000_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A1%D0%B0%D0%B2%D0%B8%D0%BD%20%D0%A5%D0%B0%D1%83%D1%81/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F-%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80/90000_Post.jpg",
        ],
      },
      {
        roomName: "Кухня",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A1%D0%B0%D0%B2%D0%B8%D0%BD%20%D0%A5%D0%B0%D1%83%D1%81/%D0%9A%D1%83%D1%85%D0%BD%D1%8F/1_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A1%D0%B0%D0%B2%D0%B8%D0%BD%20%D0%A5%D0%B0%D1%83%D1%81/%D0%9A%D1%83%D1%85%D0%BD%D1%8F/2_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A1%D0%B0%D0%B2%D0%B8%D0%BD%20%D0%A5%D0%B0%D1%83%D1%81/%D0%9A%D1%83%D1%85%D0%BD%D1%8F/3_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A1%D0%B0%D0%B2%D0%B8%D0%BD%20%D0%A5%D0%B0%D1%83%D1%81/%D0%9A%D1%83%D1%85%D0%BD%D1%8F/4_Post.jpg",
        ],
      },
      {
        roomName: "Спальня",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A1%D0%B0%D0%B2%D0%B8%D0%BD%20%D0%A5%D0%B0%D1%83%D1%81/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F/1_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A1%D0%B0%D0%B2%D0%B8%D0%BD%20%D0%A5%D0%B0%D1%83%D1%81/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F/2_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A1%D0%B0%D0%B2%D0%B8%D0%BD%20%D0%A5%D0%B0%D1%83%D1%81/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F/3_Post.jpg",
        ],
      },
      {
        roomName: "Ванная",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A1%D0%B0%D0%B2%D0%B8%D0%BD%20%D0%A5%D0%B0%D1%83%D1%81/%D0%92%D0%B0%D0%BD%D0%BD%D0%B0%D1%8F/1_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A1%D0%B0%D0%B2%D0%B8%D0%BD%20%D0%A5%D0%B0%D1%83%D1%81/%D0%92%D0%B0%D0%BD%D0%BD%D0%B0%D1%8F/2_Post.jpg",
        ],
      },
    ],
  },

  {
    id: 13,
    title: "Квартира 31м2",
    mainFoto:
      "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A1%D1%82%D0%BE%D0%BB%D0%B8%D1%87%D0%BD%D1%8B%D0%B9/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/2021-05-11%2019.25.37.JPG",
    otherFotos: [
      {
        roomName: "Гостиная",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A1%D1%82%D0%BE%D0%BB%D0%B8%D1%87%D0%BD%D1%8B%D0%B9/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/2021-05-11%2019.25.37.JPG",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A1%D1%82%D0%BE%D0%BB%D0%B8%D1%87%D0%BD%D1%8B%D0%B9/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/2021-05-11%2019.25.58.JPG",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A1%D1%82%D0%BE%D0%BB%D0%B8%D1%87%D0%BD%D1%8B%D0%B9/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/2021-05-11%2019.26.38.JPG",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A1%D1%82%D0%BE%D0%BB%D0%B8%D1%87%D0%BD%D1%8B%D0%B9/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/2021-05-11%2019.27.08.JPG",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A1%D1%82%D0%BE%D0%BB%D0%B8%D1%87%D0%BD%D1%8B%D0%B9/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/2021-05-11%2019.29.18.JPG",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A1%D1%82%D0%BE%D0%BB%D0%B8%D1%87%D0%BD%D1%8B%D0%B9/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/2021-05-11%2019.29.48.JPG",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A1%D1%82%D0%BE%D0%BB%D0%B8%D1%87%D0%BD%D1%8B%D0%B9/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/2021-05-11%2019.30.14.JPG",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A1%D1%82%D0%BE%D0%BB%D0%B8%D1%87%D0%BD%D1%8B%D0%B9/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/2021-05-12%2014.00.29.JPG",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A1%D1%82%D0%BE%D0%BB%D0%B8%D1%87%D0%BD%D1%8B%D0%B9/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/2021-05-17%2009.04.55.JPG",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A1%D1%82%D0%BE%D0%BB%D0%B8%D1%87%D0%BD%D1%8B%D0%B9/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/2021-05-20%2018.51.18.JPG",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A1%D1%82%D0%BE%D0%BB%D0%B8%D1%87%D0%BD%D1%8B%D0%B9/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/2021-05-21%2009.52.13.JPG",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A1%D1%82%D0%BE%D0%BB%D0%B8%D1%87%D0%BD%D1%8B%D0%B9/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/2021-05-21%2009.52.47.JPG",
        ],
      },
      {
        roomName: "Ванная",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A1%D1%82%D0%BE%D0%BB%D0%B8%D1%87%D0%BD%D1%8B%D0%B9/%D0%92%D0%B0%D0%BD%D0%BD%D0%B0%D1%8F/2021-05-12%2019.45.47.JPG",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A1%D1%82%D0%BE%D0%BB%D0%B8%D1%87%D0%BD%D1%8B%D0%B9/%D0%92%D0%B0%D0%BD%D0%BD%D0%B0%D1%8F/2021-05-12%2019.46.43.JPG",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A1%D1%82%D0%BE%D0%BB%D0%B8%D1%87%D0%BD%D1%8B%D0%B9/%D0%92%D0%B0%D0%BD%D0%BD%D0%B0%D1%8F/2021-05-12%2019.47.11.JPG",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A1%D1%82%D0%BE%D0%BB%D0%B8%D1%87%D0%BD%D1%8B%D0%B9/%D0%92%D0%B0%D0%BD%D0%BD%D0%B0%D1%8F/2021-05-12%2019.47.38.JPG",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A1%D1%82%D0%BE%D0%BB%D0%B8%D1%87%D0%BD%D1%8B%D0%B9/%D0%92%D0%B0%D0%BD%D0%BD%D0%B0%D1%8F/2021-05-12%2019.47.56.JPG",
        ],
      },
    ],
  },

  {
    id: 14,
    title: "Квартира 75м2",
    mainFoto:
      "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%AF%D1%88%D0%BC%D0%B0/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F%2C%20%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80%20%281%29.jpg",
    otherFotos: [
      {
        roomName: "Гостиная",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%AF%D1%88%D0%BC%D0%B0/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F%2C%20%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80%20%281%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%AF%D1%88%D0%BC%D0%B0/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F%2C%20%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80%20%282%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%AF%D1%88%D0%BC%D0%B0/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F%2C%20%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80%20%283%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%AF%D1%88%D0%BC%D0%B0/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F%2C%20%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80%20%284%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%AF%D1%88%D0%BC%D0%B0/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F%2C%20%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80%20%285%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%AF%D1%88%D0%BC%D0%B0/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F%2C%20%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80%20%286%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%AF%D1%88%D0%BC%D0%B0/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F%2C%20%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80%20%287%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%AF%D1%88%D0%BC%D0%B0/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F%2C%20%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80%20%288%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%AF%D1%88%D0%BC%D0%B0/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F%2C%20%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80%20%289%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%AF%D1%88%D0%BC%D0%B0/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F%2C%20%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80%20%2810%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%AF%D1%88%D0%BC%D0%B0/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F%2C%20%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80%20%2811%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%AF%D1%88%D0%BC%D0%B0/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F%2C%20%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80%20%2812%29.jpg",
        ],
      },
      {
        roomName: "Детская",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%AF%D1%88%D0%BC%D0%B0/%D0%94%D0%B5%D1%82%D1%81%D0%BA%D0%B0%D1%8F/%D0%94%D0%B5%D1%82%D1%81%D0%BA%D0%B0%D1%8F%20%281%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%AF%D1%88%D0%BC%D0%B0/%D0%94%D0%B5%D1%82%D1%81%D0%BA%D0%B0%D1%8F/%D0%94%D0%B5%D1%82%D1%81%D0%BA%D0%B0%D1%8F%20%282%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%AF%D1%88%D0%BC%D0%B0/%D0%94%D0%B5%D1%82%D1%81%D0%BA%D0%B0%D1%8F/%D0%94%D0%B5%D1%82%D1%81%D0%BA%D0%B0%D1%8F%20%283%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%AF%D1%88%D0%BC%D0%B0/%D0%94%D0%B5%D1%82%D1%81%D0%BA%D0%B0%D1%8F/%D0%94%D0%B5%D1%82%D1%81%D0%BA%D0%B0%D1%8F%20%284%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%AF%D1%88%D0%BC%D0%B0/%D0%94%D0%B5%D1%82%D1%81%D0%BA%D0%B0%D1%8F/%D0%94%D0%B5%D1%82%D1%81%D0%BA%D0%B0%D1%8F%20%285%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%AF%D1%88%D0%BC%D0%B0/%D0%94%D0%B5%D1%82%D1%81%D0%BA%D0%B0%D1%8F/%D0%94%D0%B5%D1%82%D1%81%D0%BA%D0%B0%D1%8F%20%286%29.jpg",
        ],
      },

      {
        roomName: "Спальная",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%AF%D1%88%D0%BC%D0%B0/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D0%B0%D1%8F/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F%20%281%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%AF%D1%88%D0%BC%D0%B0/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D0%B0%D1%8F/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F%20%282%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%AF%D1%88%D0%BC%D0%B0/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D0%B0%D1%8F/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F%20%283%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%AF%D1%88%D0%BC%D0%B0/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D0%B0%D1%8F/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F%20%284%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%AF%D1%88%D0%BC%D0%B0/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D0%B0%D1%8F/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F%20%285%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%AF%D1%88%D0%BC%D0%B0/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D0%B0%D1%8F/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F%20%286%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%AF%D1%88%D0%BC%D0%B0/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D0%B0%D1%8F/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F%20%287%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%AF%D1%88%D0%BC%D0%B0/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D0%B0%D1%8F/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F%20%288%29.jpg",
        ],
      },
      {
        roomName: "Ванная",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%AF%D1%88%D0%BC%D0%B0/%D0%92%D0%B0%D0%BD%D0%BD%D0%B0%D1%8F/%D0%92%D0%B0%D0%BD%D0%BD%D0%B0%D1%8F%20%281%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%AF%D1%88%D0%BC%D0%B0/%D0%92%D0%B0%D0%BD%D0%BD%D0%B0%D1%8F/%D0%92%D0%B0%D0%BD%D0%BD%D0%B0%D1%8F%20%282%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%AF%D1%88%D0%BC%D0%B0/%D0%92%D0%B0%D0%BD%D0%BD%D0%B0%D1%8F/%D0%92%D0%B0%D0%BD%D0%BD%D0%B0%D1%8F%20%283%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%AF%D1%88%D0%BC%D0%B0/%D0%92%D0%B0%D0%BD%D0%BD%D0%B0%D1%8F/%D0%92%D0%B0%D0%BD%D0%BD%D0%B0%D1%8F%20%284%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%AF%D1%88%D0%BC%D0%B0/%D0%92%D0%B0%D0%BD%D0%BD%D0%B0%D1%8F/%D0%92%D0%B0%D0%BD%D0%BD%D0%B0%D1%8F%20%285%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%AF%D1%88%D0%BC%D0%B0/%D0%92%D0%B0%D0%BD%D0%BD%D0%B0%D1%8F/%D0%92%D0%B0%D0%BD%D0%BD%D0%B0%D1%8F%20%286%29.jpg",
        ],
      },
      {
        roomName: "Балкон",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%AF%D1%88%D0%BC%D0%B0/%D0%91%D0%B0%D0%BB%D0%BA%D0%BE%D0%BD/%D0%91%D0%B0%D0%BB%D0%BA%D0%BE%D0%BD%20%281%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%AF%D1%88%D0%BC%D0%B0/%D0%91%D0%B0%D0%BB%D0%BA%D0%BE%D0%BD/%D0%91%D0%B0%D0%BB%D0%BA%D0%BE%D0%BD%20%282%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%AF%D1%88%D0%BC%D0%B0/%D0%91%D0%B0%D0%BB%D0%BA%D0%BE%D0%BD/%D0%91%D0%B0%D0%BB%D0%BA%D0%BE%D0%BD%20%283%29.jpg",
        ],
      },
    ],
  },
  {
    id: 15,
    title: "Квартира 90м2",
    mainFoto:
      "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%203%20%D0%91%D0%BE%D0%B3%D0%B0%D1%82%D1%8B%D1%80%D1%8F/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F%20%D0%B8%20%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80/1_Post.jpg",
    otherFotos: [
      {
        roomName: "Гостиная",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%203%20%D0%91%D0%BE%D0%B3%D0%B0%D1%82%D1%8B%D1%80%D1%8F/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F%20%D0%B8%20%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80/1_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%203%20%D0%91%D0%BE%D0%B3%D0%B0%D1%82%D1%8B%D1%80%D1%8F/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F%20%D0%B8%20%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80/2_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%203%20%D0%91%D0%BE%D0%B3%D0%B0%D1%82%D1%8B%D1%80%D1%8F/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F%20%D0%B8%20%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80/3_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%203%20%D0%91%D0%BE%D0%B3%D0%B0%D1%82%D1%8B%D1%80%D1%8F/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F%20%D0%B8%20%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80/4_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%203%20%D0%91%D0%BE%D0%B3%D0%B0%D1%82%D1%8B%D1%80%D1%8F/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F%20%D0%B8%20%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80/7_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%203%20%D0%91%D0%BE%D0%B3%D0%B0%D1%82%D1%8B%D1%80%D1%8F/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F%20%D0%B8%20%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80/8_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%203%20%D0%91%D0%BE%D0%B3%D0%B0%D1%82%D1%8B%D1%80%D1%8F/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F%20%D0%B8%20%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80/9_Post.jpg",
        ],
      },
      {
        roomName: "Детская",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%203%20%D0%91%D0%BE%D0%B3%D0%B0%D1%82%D1%8B%D1%80%D1%8F/%D0%94%D0%B5%D1%82%D1%81%D0%BA%D0%B0%D1%8F/2_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%203%20%D0%91%D0%BE%D0%B3%D0%B0%D1%82%D1%8B%D1%80%D1%8F/%D0%94%D0%B5%D1%82%D1%81%D0%BA%D0%B0%D1%8F/3_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%203%20%D0%91%D0%BE%D0%B3%D0%B0%D1%82%D1%8B%D1%80%D1%8F/%D0%94%D0%B5%D1%82%D1%81%D0%BA%D0%B0%D1%8F/%D0%B4%D0%B5%D1%82.jpg",
        ],
      },
      {
        roomName: "Спальная",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%203%20%D0%91%D0%BE%D0%B3%D0%B0%D1%82%D1%8B%D1%80%D1%8F/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F/1_Post%20%281%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%203%20%D0%91%D0%BE%D0%B3%D0%B0%D1%82%D1%8B%D1%80%D1%8F/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F/3_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%203%20%D0%91%D0%BE%D0%B3%D0%B0%D1%82%D1%8B%D1%80%D1%8F/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F/4_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%203%20%D0%91%D0%BE%D0%B3%D0%B0%D1%82%D1%8B%D1%80%D1%8F/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F/2_Post.jpg",
        ],
      },
      {
        roomName: "Ванная",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%203%20%D0%91%D0%BE%D0%B3%D0%B0%D1%82%D1%8B%D1%80%D1%8F/%D0%92%D0%B0%D0%BD%D0%BD%D0%B0%D1%8F/1.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%203%20%D0%91%D0%BE%D0%B3%D0%B0%D1%82%D1%8B%D1%80%D1%8F/%D0%92%D0%B0%D0%BD%D0%BD%D0%B0%D1%8F/2_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%203%20%D0%91%D0%BE%D0%B3%D0%B0%D1%82%D1%8B%D1%80%D1%8F/%D0%92%D0%B0%D0%BD%D0%BD%D0%B0%D1%8F/3_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%203%20%D0%91%D0%BE%D0%B3%D0%B0%D1%82%D1%8B%D1%80%D1%8F/%D0%92%D0%B0%D0%BD%D0%BD%D0%B0%D1%8F/4_Post.jpg",
        ],
      },
      {
        roomName: "Балкон",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%203%20%D0%91%D0%BE%D0%B3%D0%B0%D1%82%D1%8B%D1%80%D1%8F/%D0%91%D0%B0%D0%BB%D0%BA%D0%BE%D0%BD/1_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%203%20%D0%91%D0%BE%D0%B3%D0%B0%D1%82%D1%8B%D1%80%D1%8F/%D0%91%D0%B0%D0%BB%D0%BA%D0%BE%D0%BD/2_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%203%20%D0%91%D0%BE%D0%B3%D0%B0%D1%82%D1%8B%D1%80%D1%8F/%D0%91%D0%B0%D0%BB%D0%BA%D0%BE%D0%BD/3_Post.jpg",
        ],
      },
    ],
  },
  {
    id: 16,
    title: "Квартира 99м2",
    mainFoto:
      "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%90%D1%80%D1%82%20%D0%A1%D0%B8%D1%82%D0%B8/%D0%9A%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80-%D0%B3%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F-%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80%20%283%29.jpg",
    otherFotos: [
      {
        roomName: "Гостиная",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%90%D1%80%D1%82%20%D0%A1%D0%B8%D1%82%D0%B8/%D0%9A%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80-%D0%B3%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F-%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80%20%281%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%90%D1%80%D1%82%20%D0%A1%D0%B8%D1%82%D0%B8/%D0%9A%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80-%D0%B3%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F-%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80%20%282%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%90%D1%80%D1%82%20%D0%A1%D0%B8%D1%82%D0%B8/%D0%9A%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80-%D0%B3%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F-%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80%20%283%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%90%D1%80%D1%82%20%D0%A1%D0%B8%D1%82%D0%B8/%D0%9A%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80-%D0%B3%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F-%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80%20%284%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%90%D1%80%D1%82%20%D0%A1%D0%B8%D1%82%D0%B8/%D0%9A%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80-%D0%B3%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F-%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80%20%285%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%90%D1%80%D1%82%20%D0%A1%D0%B8%D1%82%D0%B8/%D0%9A%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80-%D0%B3%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F-%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80%20%286%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%90%D1%80%D1%82%20%D0%A1%D0%B8%D1%82%D0%B8/%D0%9A%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80-%D0%B3%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F-%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80%20%287%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%90%D1%80%D1%82%20%D0%A1%D0%B8%D1%82%D0%B8/%D0%9A%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80-%D0%B3%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F-%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80%20%288%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%90%D1%80%D1%82%20%D0%A1%D0%B8%D1%82%D0%B8/%D0%9A%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80-%D0%B3%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F-%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80%20%289%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%90%D1%80%D1%82%20%D0%A1%D0%B8%D1%82%D0%B8/%D0%9A%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80-%D0%B3%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F-%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80%20%2810%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%90%D1%80%D1%82%20%D0%A1%D0%B8%D1%82%D0%B8/%D0%9A%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80-%D0%B3%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F-%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80%20%2811%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%90%D1%80%D1%82%20%D0%A1%D0%B8%D1%82%D0%B8/%D0%9A%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80-%D0%B3%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F-%D0%BA%D0%BE%D1%80%D0%B8%D0%B4%D0%BE%D1%80%20%2812%29.jpg",
        ],
      },
      {
        roomName: "Детская",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%90%D1%80%D1%82%20%D0%A1%D0%B8%D1%82%D0%B8/%D0%9A%D0%B0%D0%BC%D0%B8%D0%BB%D0%B0/%D0%9A%D0%BE%D0%BC%D0%BD%D0%B0%D1%82%D0%B0%20%D0%9A%D0%B0%D0%BC%D0%B8%D0%BB%D1%8B%20%281%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%90%D1%80%D1%82%20%D0%A1%D0%B8%D1%82%D0%B8/%D0%9A%D0%B0%D0%BC%D0%B8%D0%BB%D0%B0/%D0%9A%D0%BE%D0%BC%D0%BD%D0%B0%D1%82%D0%B0%20%D0%9A%D0%B0%D0%BC%D0%B8%D0%BB%D1%8B%20%282%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%90%D1%80%D1%82%20%D0%A1%D0%B8%D1%82%D0%B8/%D0%9A%D0%B0%D0%BC%D0%B8%D0%BB%D0%B0/%D0%9A%D0%BE%D0%BC%D0%BD%D0%B0%D1%82%D0%B0%20%D0%9A%D0%B0%D0%BC%D0%B8%D0%BB%D1%8B%20%283%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%90%D1%80%D1%82%20%D0%A1%D0%B8%D1%82%D0%B8/%D0%9A%D0%B0%D0%BC%D0%B8%D0%BB%D0%B0/%D0%9A%D0%BE%D0%BC%D0%BD%D0%B0%D1%82%D0%B0%20%D0%9A%D0%B0%D0%BC%D0%B8%D0%BB%D1%8B%20%284%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%90%D1%80%D1%82%20%D0%A1%D0%B8%D1%82%D0%B8/%D0%A1%D0%B0%D0%BC%D0%B0%D1%82/%D0%9A%D0%BE%D0%BC%D0%BD%D0%B0%D1%82%D0%B0%20%D0%A1%D0%B0%D0%BC%D0%B0%D1%82%D0%B0%20%281%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%90%D1%80%D1%82%20%D0%A1%D0%B8%D1%82%D0%B8/%D0%A1%D0%B0%D0%BC%D0%B0%D1%82/%D0%9A%D0%BE%D0%BC%D0%BD%D0%B0%D1%82%D0%B0%20%D0%A1%D0%B0%D0%BC%D0%B0%D1%82%D0%B0%20%282%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%90%D1%80%D1%82%20%D0%A1%D0%B8%D1%82%D0%B8/%D0%A1%D0%B0%D0%BC%D0%B0%D1%82/%D0%9A%D0%BE%D0%BC%D0%BD%D0%B0%D1%82%D0%B0%20%D0%A1%D0%B0%D0%BC%D0%B0%D1%82%D0%B0%20%283%29.jpg",
        ],
      },
      {
        roomName: "Спальная",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%90%D1%80%D1%82%20%D0%A1%D0%B8%D1%82%D0%B8/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F%20%D0%B2%D0%B8%D0%B7%20%281%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%90%D1%80%D1%82%20%D0%A1%D0%B8%D1%82%D0%B8/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F%20%D0%B2%D0%B8%D0%B7%20%282%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%90%D1%80%D1%82%20%D0%A1%D0%B8%D1%82%D0%B8/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F%20%D0%B2%D0%B8%D0%B7%20%283%29.jpg",
        ],
      },
      {
        roomName: "Ванная",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%90%D1%80%D1%82%20%D0%A1%D0%B8%D1%82%D0%B8/%D0%92%D0%B0%D0%BD%D0%BD%D0%B0%D1%8F/1_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%90%D1%80%D1%82%20%D0%A1%D0%B8%D1%82%D0%B8/%D0%92%D0%B0%D0%BD%D0%BD%D0%B0%D1%8F/%D0%A1%D0%B8%D0%BD%D1%8F%D1%8F%20%D0%B2%D0%B0%D0%BD%D0%BD%D0%B0%D1%8F%20%282%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%90%D1%80%D1%82%20%D0%A1%D0%B8%D1%82%D0%B8/%D0%92%D0%B0%D0%BD%D0%BD%D0%B0%D1%8F/%D0%A1%D0%B8%D0%BD%D1%8F%D1%8F%20%D0%B2%D0%B0%D0%BD%D0%BD%D0%B0%D1%8F%20%283%29.jpg",
        ],
      },
      {
        roomName: "Балкон",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%90%D1%80%D1%82%20%D0%A1%D0%B8%D1%82%D0%B8/%D0%91%D0%B0%D0%BB%D0%BA%D0%BE%D0%BD%20%D1%80%D1%8F%D0%B4%D0%BE%D0%BC%20%D1%81%D0%BE%20%D1%81%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D0%B5%D0%B9/%D0%91%D0%B0%D0%BB%D0%BA%D0%BE%D0%BD%20%D1%80%D1%8F%D0%B4%D0%BE%D0%BC%20%D1%81%D0%BE%20%D1%81%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D0%B5%D0%B9%20%281%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%90%D1%80%D1%82%20%D0%A1%D0%B8%D1%82%D0%B8/%D0%91%D0%B0%D0%BB%D0%BA%D0%BE%D0%BD%20%D1%80%D1%8F%D0%B4%D0%BE%D0%BC%20%D1%81%D0%BE%20%D1%81%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D0%B5%D0%B9/%D0%91%D0%B0%D0%BB%D0%BA%D0%BE%D0%BD%20%D1%80%D1%8F%D0%B4%D0%BE%D0%BC%20%D1%81%D0%BE%20%D1%81%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D0%B5%D0%B9%20%282%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%90%D1%80%D1%82%20%D0%A1%D0%B8%D1%82%D0%B8/%D0%91%D0%B0%D0%BB%D0%BA%D0%BE%D0%BD%20%D1%80%D1%8F%D0%B4%D0%BE%D0%BC%20%D1%81%D0%BE%20%D1%81%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D0%B5%D0%B9/%D0%91%D0%B0%D0%BB%D0%BA%D0%BE%D0%BD%20%D1%80%D1%8F%D0%B4%D0%BE%D0%BC%20%D1%81%D0%BE%20%D1%81%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D0%B5%D0%B9%20%283%29.jpg",
        ],
      },
    ],
  },

  {
    id: 17,
    title: "Квартира 57м2",
    mainFoto:
      "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%97%D0%B0%D0%BA%D0%B8%D0%B5%D0%B2%D0%B0/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/1_Post.jpg",
    otherFotos: [
      {
        roomName: "Гостиная",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%97%D0%B0%D0%BA%D0%B8%D0%B5%D0%B2%D0%B0/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/1_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%97%D0%B0%D0%BA%D0%B8%D0%B5%D0%B2%D0%B0/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/2_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%97%D0%B0%D0%BA%D0%B8%D0%B5%D0%B2%D0%B0/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/3_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%97%D0%B0%D0%BA%D0%B8%D0%B5%D0%B2%D0%B0/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/4_Post.jpg",
        ],
      },
      {
        roomName: "Детская",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%97%D0%B0%D0%BA%D0%B8%D0%B5%D0%B2%D0%B0/%D0%94%D0%B5%D1%82%D1%81%D0%BA%D0%B0%D1%8F/1_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%97%D0%B0%D0%BA%D0%B8%D0%B5%D0%B2%D0%B0/%D0%94%D0%B5%D1%82%D1%81%D0%BA%D0%B0%D1%8F/2_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%97%D0%B0%D0%BA%D0%B8%D0%B5%D0%B2%D0%B0/%D0%94%D0%B5%D1%82%D1%81%D0%BA%D0%B0%D1%8F/3_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%97%D0%B0%D0%BA%D0%B8%D0%B5%D0%B2%D0%B0/%D0%94%D0%B5%D1%82%D1%81%D0%BA%D0%B0%D1%8F/4_Post.jpg",
        ],
      },
      {
        roomName: "Кухня",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%97%D0%B0%D0%BA%D0%B8%D0%B5%D0%B2%D0%B0/%D0%9A%D1%83%D1%85%D0%BD%D1%8F/1_Post.jpg",

          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%97%D0%B0%D0%BA%D0%B8%D0%B5%D0%B2%D0%B0/%D0%9A%D1%83%D1%85%D0%BD%D1%8F/2_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%97%D0%B0%D0%BA%D0%B8%D0%B5%D0%B2%D0%B0/%D0%9A%D1%83%D1%85%D0%BD%D1%8F/3_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%97%D0%B0%D0%BA%D0%B8%D0%B5%D0%B2%D0%B0/%D0%9A%D1%83%D1%85%D0%BD%D1%8F/5_Post.jpg",
        ],
      },
      {
        roomName: "Ванная",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%97%D0%B0%D0%BA%D0%B8%D0%B5%D0%B2%D0%B0/%D0%92%D0%B0%D0%BD%D0%BD%D0%B0%D1%8F/1_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%97%D0%B0%D0%BA%D0%B8%D0%B5%D0%B2%D0%B0/%D0%92%D0%B0%D0%BD%D0%BD%D0%B0%D1%8F/2_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%97%D0%B0%D0%BA%D0%B8%D0%B5%D0%B2%D0%B0/%D0%92%D0%B0%D0%BD%D0%BD%D0%B0%D1%8F/3_Post.jpg",
        ],
      },
    ],
  },
  {
    id: 18,
    title: "Детская мальчика 21м2",
    mainFoto:
      "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A2%D0%BE%D0%BB%D1%81%D1%82%D0%BE%D0%B3%D0%BE/%D0%94%D0%B5%D1%82%D1%81%D0%BA%D0%B0%D1%8F/1_Post.jpg",
    otherFotos: [
      {
        roomName: "Детская",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A2%D0%BE%D0%BB%D1%81%D1%82%D0%BE%D0%B3%D0%BE/%D0%94%D0%B5%D1%82%D1%81%D0%BA%D0%B0%D1%8F/1_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A2%D0%BE%D0%BB%D1%81%D1%82%D0%BE%D0%B3%D0%BE/%D0%94%D0%B5%D1%82%D1%81%D0%BA%D0%B0%D1%8F/2_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A2%D0%BE%D0%BB%D1%81%D1%82%D0%BE%D0%B3%D0%BE/%D0%94%D0%B5%D1%82%D1%81%D0%BA%D0%B0%D1%8F/3_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A2%D0%BE%D0%BB%D1%81%D1%82%D0%BE%D0%B3%D0%BE/%D0%94%D0%B5%D1%82%D1%81%D0%BA%D0%B0%D1%8F/4_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A2%D0%BE%D0%BB%D1%81%D1%82%D0%BE%D0%B3%D0%BE/%D0%94%D0%B5%D1%82%D1%81%D0%BA%D0%B0%D1%8F/5_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%A2%D0%BE%D0%BB%D1%81%D1%82%D0%BE%D0%B3%D0%BE/%D0%94%D0%B5%D1%82%D1%81%D0%BA%D0%B0%D1%8F/6_Post.jpg",
        ],
      },
    ],
  },
  {
    id: 19,
    title: "Дом 122м2",
    mainFoto:
      "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9F%D0%B5%D1%81%D1%87%D0%B0%D0%BD%D0%BD%D1%8B%D0%B5%20%D0%9A%D0%BE%D0%B2%D0%B0%D0%BB%D0%B8/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/6_Post.jpg",
    otherFotos: [
      {
        roomName: "Гостиная",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9F%D0%B5%D1%81%D1%87%D0%B0%D0%BD%D0%BD%D1%8B%D0%B5%20%D0%9A%D0%BE%D0%B2%D0%B0%D0%BB%D0%B8/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/1_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9F%D0%B5%D1%81%D1%87%D0%B0%D0%BD%D0%BD%D1%8B%D0%B5%20%D0%9A%D0%BE%D0%B2%D0%B0%D0%BB%D0%B8/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/2_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9F%D0%B5%D1%81%D1%87%D0%B0%D0%BD%D0%BD%D1%8B%D0%B5%20%D0%9A%D0%BE%D0%B2%D0%B0%D0%BB%D0%B8/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/3_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9F%D0%B5%D1%81%D1%87%D0%B0%D0%BD%D0%BD%D1%8B%D0%B5%20%D0%9A%D0%BE%D0%B2%D0%B0%D0%BB%D0%B8/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/4_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9F%D0%B5%D1%81%D1%87%D0%B0%D0%BD%D0%BD%D1%8B%D0%B5%20%D0%9A%D0%BE%D0%B2%D0%B0%D0%BB%D0%B8/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/5_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9F%D0%B5%D1%81%D1%87%D0%B0%D0%BD%D0%BD%D1%8B%D0%B5%20%D0%9A%D0%BE%D0%B2%D0%B0%D0%BB%D0%B8/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/6_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9F%D0%B5%D1%81%D1%87%D0%B0%D0%BD%D0%BD%D1%8B%D0%B5%20%D0%9A%D0%BE%D0%B2%D0%B0%D0%BB%D0%B8/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/7_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9F%D0%B5%D1%81%D1%87%D0%B0%D0%BD%D0%BD%D1%8B%D0%B5%20%D0%9A%D0%BE%D0%B2%D0%B0%D0%BB%D0%B8/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/8_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9F%D0%B5%D1%81%D1%87%D0%B0%D0%BD%D0%BD%D1%8B%D0%B5%20%D0%9A%D0%BE%D0%B2%D0%B0%D0%BB%D0%B8/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/9_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9F%D0%B5%D1%81%D1%87%D0%B0%D0%BD%D0%BD%D1%8B%D0%B5%20%D0%9A%D0%BE%D0%B2%D0%B0%D0%BB%D0%B8/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/10_Post.jpg",
        ],
      },
      {
        roomName: "Спальня",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9F%D0%B5%D1%81%D1%87%D0%B0%D0%BD%D0%BD%D1%8B%D0%B5%20%D0%9A%D0%BE%D0%B2%D0%B0%D0%BB%D0%B8/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F/1_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9F%D0%B5%D1%81%D1%87%D0%B0%D0%BD%D0%BD%D1%8B%D0%B5%20%D0%9A%D0%BE%D0%B2%D0%B0%D0%BB%D0%B8/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F/2_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9F%D0%B5%D1%81%D1%87%D0%B0%D0%BD%D0%BD%D1%8B%D0%B5%20%D0%9A%D0%BE%D0%B2%D0%B0%D0%BB%D0%B8/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F/3_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9F%D0%B5%D1%81%D1%87%D0%B0%D0%BD%D0%BD%D1%8B%D0%B5%20%D0%9A%D0%BE%D0%B2%D0%B0%D0%BB%D0%B8/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F/4_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9F%D0%B5%D1%81%D1%87%D0%B0%D0%BD%D0%BD%D1%8B%D0%B5%20%D0%9A%D0%BE%D0%B2%D0%B0%D0%BB%D0%B8/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F/5_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9F%D0%B5%D1%81%D1%87%D0%B0%D0%BD%D0%BD%D1%8B%D0%B5%20%D0%9A%D0%BE%D0%B2%D0%B0%D0%BB%D0%B8/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F/6_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9F%D0%B5%D1%81%D1%87%D0%B0%D0%BD%D0%BD%D1%8B%D0%B5%20%D0%9A%D0%BE%D0%B2%D0%B0%D0%BB%D0%B8/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F/7_Post.jpg",
        ],
      },
      {
        roomName: "Детская",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9F%D0%B5%D1%81%D1%87%D0%B0%D0%BD%D0%BD%D1%8B%D0%B5%20%D0%9A%D0%BE%D0%B2%D0%B0%D0%BB%D0%B8/%D0%94%D0%B5%D1%82%D1%81%D0%BA%D0%B0%D1%8F/1_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9F%D0%B5%D1%81%D1%87%D0%B0%D0%BD%D0%BD%D1%8B%D0%B5%20%D0%9A%D0%BE%D0%B2%D0%B0%D0%BB%D0%B8/%D0%94%D0%B5%D1%82%D1%81%D0%BA%D0%B0%D1%8F/2_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9F%D0%B5%D1%81%D1%87%D0%B0%D0%BD%D0%BD%D1%8B%D0%B5%20%D0%9A%D0%BE%D0%B2%D0%B0%D0%BB%D0%B8/%D0%94%D0%B5%D1%82%D1%81%D0%BA%D0%B0%D1%8F/4_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9F%D0%B5%D1%81%D1%87%D0%B0%D0%BD%D0%BD%D1%8B%D0%B5%20%D0%9A%D0%BE%D0%B2%D0%B0%D0%BB%D0%B8/%D0%94%D0%B5%D1%82%D1%81%D0%BA%D0%B0%D1%8F/13_Post.jpg",
        ],
      },
      {
        roomName: "Ванная",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9F%D0%B5%D1%81%D1%87%D0%B0%D0%BD%D0%BD%D1%8B%D0%B5%20%D0%9A%D0%BE%D0%B2%D0%B0%D0%BB%D0%B8/%D0%92%D0%B0%D0%BD%D0%BD%D0%B0%D1%8F/1_Post1.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9F%D0%B5%D1%81%D1%87%D0%B0%D0%BD%D0%BD%D1%8B%D0%B5%20%D0%9A%D0%BE%D0%B2%D0%B0%D0%BB%D0%B8/%D0%92%D0%B0%D0%BD%D0%BD%D0%B0%D1%8F/2_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9F%D0%B5%D1%81%D1%87%D0%B0%D0%BD%D0%BD%D1%8B%D0%B5%20%D0%9A%D0%BE%D0%B2%D0%B0%D0%BB%D0%B8/%D0%92%D0%B0%D0%BD%D0%BD%D0%B0%D1%8F/3_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9F%D0%B5%D1%81%D1%87%D0%B0%D0%BD%D0%BD%D1%8B%D0%B5%20%D0%9A%D0%BE%D0%B2%D0%B0%D0%BB%D0%B8/%D0%92%D0%B0%D0%BD%D0%BD%D0%B0%D1%8F/4_Post.jpg",
        ],
      },
      {
        roomName: "Кабинет",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9F%D0%B5%D1%81%D1%87%D0%B0%D0%BD%D0%BD%D1%8B%D0%B5%20%D0%9A%D0%BE%D0%B2%D0%B0%D0%BB%D0%B8/%D0%9A%D0%B0%D0%B1%D0%B8%D0%BD%D0%B5%D1%82/1_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9F%D0%B5%D1%81%D1%87%D0%B0%D0%BD%D0%BD%D1%8B%D0%B5%20%D0%9A%D0%BE%D0%B2%D0%B0%D0%BB%D0%B8/%D0%9A%D0%B0%D0%B1%D0%B8%D0%BD%D0%B5%D1%82/2_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9F%D0%B5%D1%81%D1%87%D0%B0%D0%BD%D0%BD%D1%8B%D0%B5%20%D0%9A%D0%BE%D0%B2%D0%B0%D0%BB%D0%B8/%D0%9A%D0%B0%D0%B1%D0%B8%D0%BD%D0%B5%D1%82/3_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9F%D0%B5%D1%81%D1%87%D0%B0%D0%BD%D0%BD%D1%8B%D0%B5%20%D0%9A%D0%BE%D0%B2%D0%B0%D0%BB%D0%B8/%D0%9A%D0%B0%D0%B1%D0%B8%D0%BD%D0%B5%D1%82/4_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9F%D0%B5%D1%81%D1%87%D0%B0%D0%BD%D0%BD%D1%8B%D0%B5%20%D0%9A%D0%BE%D0%B2%D0%B0%D0%BB%D0%B8/%D0%9A%D0%B0%D0%B1%D0%B8%D0%BD%D0%B5%D1%82/5_Post.jpg",
        ],
      },
      {
        roomName: "Лестинца",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9F%D0%B5%D1%81%D1%87%D0%B0%D0%BD%D0%BD%D1%8B%D0%B5%20%D0%9A%D0%BE%D0%B2%D0%B0%D0%BB%D0%B8/%D0%9B%D0%B5%D1%81%D1%82%D0%BD%D0%B8%D1%86%D0%B0/1_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9F%D0%B5%D1%81%D1%87%D0%B0%D0%BD%D0%BD%D1%8B%D0%B5%20%D0%9A%D0%BE%D0%B2%D0%B0%D0%BB%D0%B8/%D0%9B%D0%B5%D1%81%D1%82%D0%BD%D0%B8%D1%86%D0%B0/2_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9F%D0%B5%D1%81%D1%87%D0%B0%D0%BD%D0%BD%D1%8B%D0%B5%20%D0%9A%D0%BE%D0%B2%D0%B0%D0%BB%D0%B8/%D0%9B%D0%B5%D1%81%D1%82%D0%BD%D0%B8%D1%86%D0%B0/3_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9F%D0%B5%D1%81%D1%87%D0%B0%D0%BD%D0%BD%D1%8B%D0%B5%20%D0%9A%D0%BE%D0%B2%D0%B0%D0%BB%D0%B8/%D0%9B%D0%B5%D1%81%D1%82%D0%BD%D0%B8%D1%86%D0%B0/4_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9F%D0%B5%D1%81%D1%87%D0%B0%D0%BD%D0%BD%D1%8B%D0%B5%20%D0%9A%D0%BE%D0%B2%D0%B0%D0%BB%D0%B8/%D0%9B%D0%B5%D1%81%D1%82%D0%BD%D0%B8%D1%86%D0%B0/5_Post.jpg",
        ],
      },
    ],
  },
  {
    id: 20,
    title: "Дом 129м2",
    mainFoto:
      "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%96%D0%9A%20%D0%94%D0%BE%D0%BC%20%D0%BD%D0%B0%20%D0%94%D0%B0%D1%83%D1%80%D1%81%D0%BA%D0%BE%D0%B9/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/%D0%B3%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F%20%20%281%29.jpg",
    otherFotos: [
      {
        roomName: "Гостиная",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%96%D0%9A%20%D0%94%D0%BE%D0%BC%20%D0%BD%D0%B0%20%D0%94%D0%B0%D1%83%D1%80%D1%81%D0%BA%D0%BE%D0%B9/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/%D0%B3%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F%20%20%281%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%96%D0%9A%20%D0%94%D0%BE%D0%BC%20%D0%BD%D0%B0%20%D0%94%D0%B0%D1%83%D1%80%D1%81%D0%BA%D0%BE%D0%B9/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/%D0%B3%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F%20%20%288%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%96%D0%9A%20%D0%94%D0%BE%D0%BC%20%D0%BD%D0%B0%20%D0%94%D0%B0%D1%83%D1%80%D1%81%D0%BA%D0%BE%D0%B9/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/%D0%B3%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F%20%20%282%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%96%D0%9A%20%D0%94%D0%BE%D0%BC%20%D0%BD%D0%B0%20%D0%94%D0%B0%D1%83%D1%80%D1%81%D0%BA%D0%BE%D0%B9/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/%D0%B3%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F%20%20%283%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%96%D0%9A%20%D0%94%D0%BE%D0%BC%20%D0%BD%D0%B0%20%D0%94%D0%B0%D1%83%D1%80%D1%81%D0%BA%D0%BE%D0%B9/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/%D0%B3%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F%20%20%284%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%96%D0%9A%20%D0%94%D0%BE%D0%BC%20%D0%BD%D0%B0%20%D0%94%D0%B0%D1%83%D1%80%D1%81%D0%BA%D0%BE%D0%B9/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/%D0%B3%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F%20%20%285%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%96%D0%9A%20%D0%94%D0%BE%D0%BC%20%D0%BD%D0%B0%20%D0%94%D0%B0%D1%83%D1%80%D1%81%D0%BA%D0%BE%D0%B9/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/%D0%B3%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F%20%20%286%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%96%D0%9A%20%D0%94%D0%BE%D0%BC%20%D0%BD%D0%B0%20%D0%94%D0%B0%D1%83%D1%80%D1%81%D0%BA%D0%BE%D0%B9/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/%D0%B3%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F%20%20%287%29.jpg",
        ],
      },
      {
        roomName: "Спальня",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%96%D0%9A%20%D0%94%D0%BE%D0%BC%20%D0%BD%D0%B0%20%D0%94%D0%B0%D1%83%D1%80%D1%81%D0%BA%D0%BE%D0%B9/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F/1_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%96%D0%9A%20%D0%94%D0%BE%D0%BC%20%D0%BD%D0%B0%20%D0%94%D0%B0%D1%83%D1%80%D1%81%D0%BA%D0%BE%D0%B9/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F/2_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%96%D0%9A%20%D0%94%D0%BE%D0%BC%20%D0%BD%D0%B0%20%D0%94%D0%B0%D1%83%D1%80%D1%81%D0%BA%D0%BE%D0%B9/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F/3_Post.jpg",
        ],
      },
      {
        roomName: "Комната девочки",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%96%D0%9A%20%D0%94%D0%BE%D0%BC%20%D0%BD%D0%B0%20%D0%94%D0%B0%D1%83%D1%80%D1%81%D0%BA%D0%BE%D0%B9/%D0%9A%D0%BC%D0%BD%D0%B0%D1%82%D0%B0%20%D0%B4%D0%B5%D0%B2%D0%BE%D1%87%D0%BA%D0%B8/1_Post.jpg",

          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%96%D0%9A%20%D0%94%D0%BE%D0%BC%20%D0%BD%D0%B0%20%D0%94%D0%B0%D1%83%D1%80%D1%81%D0%BA%D0%BE%D0%B9/%D0%9A%D0%BC%D0%BD%D0%B0%D1%82%D0%B0%20%D0%B4%D0%B5%D0%B2%D0%BE%D1%87%D0%BA%D0%B8/2_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%96%D0%9A%20%D0%94%D0%BE%D0%BC%20%D0%BD%D0%B0%20%D0%94%D0%B0%D1%83%D1%80%D1%81%D0%BA%D0%BE%D0%B9/%D0%9A%D0%BC%D0%BD%D0%B0%D1%82%D0%B0%20%D0%B4%D0%B5%D0%B2%D0%BE%D1%87%D0%BA%D0%B8/3_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%96%D0%9A%20%D0%94%D0%BE%D0%BC%20%D0%BD%D0%B0%20%D0%94%D0%B0%D1%83%D1%80%D1%81%D0%BA%D0%BE%D0%B9/%D0%9A%D0%BC%D0%BD%D0%B0%D1%82%D0%B0%20%D0%B4%D0%B5%D0%B2%D0%BE%D1%87%D0%BA%D0%B8/4_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%96%D0%9A%20%D0%94%D0%BE%D0%BC%20%D0%BD%D0%B0%20%D0%94%D0%B0%D1%83%D1%80%D1%81%D0%BA%D0%BE%D0%B9/%D0%9A%D0%BC%D0%BD%D0%B0%D1%82%D0%B0%20%D0%B4%D0%B5%D0%B2%D0%BE%D1%87%D0%BA%D0%B8/5_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%96%D0%9A%20%D0%94%D0%BE%D0%BC%20%D0%BD%D0%B0%20%D0%94%D0%B0%D1%83%D1%80%D1%81%D0%BA%D0%BE%D0%B9/%D0%9A%D0%BC%D0%BD%D0%B0%D1%82%D0%B0%20%D0%B4%D0%B5%D0%B2%D0%BE%D1%87%D0%BA%D0%B8/6_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%96%D0%9A%20%D0%94%D0%BE%D0%BC%20%D0%BD%D0%B0%20%D0%94%D0%B0%D1%83%D1%80%D1%81%D0%BA%D0%BE%D0%B9/%D0%9A%D0%BE%D0%BC%D0%BD%D0%B0%D1%82%D0%B0%20%D0%B4%D0%B5%D0%B2%D0%BE%D1%87%D0%BA%D0%B8.%20%D0%93%D0%B0%D1%80%D0%B4%D0%B5%D1%80%D0%BE%D0%B1%D0%BD%D0%B0%D1%8F/1_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%96%D0%9A%20%D0%94%D0%BE%D0%BC%20%D0%BD%D0%B0%20%D0%94%D0%B0%D1%83%D1%80%D1%81%D0%BA%D0%BE%D0%B9/%D0%9A%D0%BE%D0%BC%D0%BD%D0%B0%D1%82%D0%B0%20%D0%B4%D0%B5%D0%B2%D0%BE%D1%87%D0%BA%D0%B8.%20%D0%93%D0%B0%D1%80%D0%B4%D0%B5%D1%80%D0%BE%D0%B1%D0%BD%D0%B0%D1%8F/2_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%96%D0%9A%20%D0%94%D0%BE%D0%BC%20%D0%BD%D0%B0%20%D0%94%D0%B0%D1%83%D1%80%D1%81%D0%BA%D0%BE%D0%B9/%D0%9A%D0%BE%D0%BC%D0%BD%D0%B0%D1%82%D0%B0%20%D0%B4%D0%B5%D0%B2%D0%BE%D1%87%D0%BA%D0%B8.%20%D0%93%D0%B0%D1%80%D0%B4%D0%B5%D1%80%D0%BE%D0%B1%D0%BD%D0%B0%D1%8F/3_Post.jpg",
        ],
      },
      {
        roomName: "Комната мальчика",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%96%D0%9A%20%D0%94%D0%BE%D0%BC%20%D0%BD%D0%B0%20%D0%94%D0%B0%D1%83%D1%80%D1%81%D0%BA%D0%BE%D0%B9/%D0%9A%D0%BE%D0%BC%D0%BD%D0%B0%D1%82%D0%B0%20%D0%BC%D0%B0%D0%BB%D1%8C%D1%87%D0%B8%D0%BA/1_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%96%D0%9A%20%D0%94%D0%BE%D0%BC%20%D0%BD%D0%B0%20%D0%94%D0%B0%D1%83%D1%80%D1%81%D0%BA%D0%BE%D0%B9/%D0%9A%D0%BE%D0%BC%D0%BD%D0%B0%D1%82%D0%B0%20%D0%BC%D0%B0%D0%BB%D1%8C%D1%87%D0%B8%D0%BA/2_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%96%D0%9A%20%D0%94%D0%BE%D0%BC%20%D0%BD%D0%B0%20%D0%94%D0%B0%D1%83%D1%80%D1%81%D0%BA%D0%BE%D0%B9/%D0%9A%D0%BE%D0%BC%D0%BD%D0%B0%D1%82%D0%B0%20%D0%BC%D0%B0%D0%BB%D1%8C%D1%87%D0%B8%D0%BA/3_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%96%D0%9A%20%D0%94%D0%BE%D0%BC%20%D0%BD%D0%B0%20%D0%94%D0%B0%D1%83%D1%80%D1%81%D0%BA%D0%BE%D0%B9/%D0%9A%D0%BE%D0%BC%D0%BD%D0%B0%D1%82%D0%B0%20%D0%BC%D0%B0%D0%BB%D1%8C%D1%87%D0%B8%D0%BA/4_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%96%D0%9A%20%D0%94%D0%BE%D0%BC%20%D0%BD%D0%B0%20%D0%94%D0%B0%D1%83%D1%80%D1%81%D0%BA%D0%BE%D0%B9/%D0%9A%D0%BE%D0%BC%D0%BD%D0%B0%D1%82%D0%B0%20%D0%BC%D0%B0%D0%BB%D1%8C%D1%87%D0%B8%D0%BA/5_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%96%D0%9A%20%D0%94%D0%BE%D0%BC%20%D0%BD%D0%B0%20%D0%94%D0%B0%D1%83%D1%80%D1%81%D0%BA%D0%BE%D0%B9/%D0%9A%D0%BE%D0%BC%D0%BD%D0%B0%D1%82%D0%B0%20%D0%BC%D0%B0%D0%BB%D1%8C%D1%87%D0%B8%D0%BA/6_Post.jpg",
        ],
      },
      {
        roomName: "Ванная",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%96%D0%9A%20%D0%94%D0%BE%D0%BC%20%D0%BD%D0%B0%20%D0%94%D0%B0%D1%83%D1%80%D1%81%D0%BA%D0%BE%D0%B9/%D0%92%D0%B0%D0%BD%D0%BD%D0%B0/1_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%96%D0%9A%20%D0%94%D0%BE%D0%BC%20%D0%BD%D0%B0%20%D0%94%D0%B0%D1%83%D1%80%D1%81%D0%BA%D0%BE%D0%B9/%D0%92%D0%B0%D0%BD%D0%BD%D0%B0/2_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%96%D0%9A%20%D0%94%D0%BE%D0%BC%20%D0%BD%D0%B0%20%D0%94%D0%B0%D1%83%D1%80%D1%81%D0%BA%D0%BE%D0%B9/%D0%92%D0%B0%D0%BD%D0%BD%D0%B0/3_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%96%D0%9A%20%D0%94%D0%BE%D0%BC%20%D0%BD%D0%B0%20%D0%94%D0%B0%D1%83%D1%80%D1%81%D0%BA%D0%BE%D0%B9/%D0%92%D0%B0%D0%BD%D0%BD%D0%B0/4_Post.jpg",
        ],
      },
      {
        roomName: "Кабинет",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%96%D0%9A%20%D0%94%D0%BE%D0%BC%20%D0%BD%D0%B0%20%D0%94%D0%B0%D1%83%D1%80%D1%81%D0%BA%D0%BE%D0%B9/%D0%9A%D0%B0%D0%B1%D0%B8%D0%BD%D0%B5%D1%82%201/%D0%BA%D0%B0%D0%B1%D0%B8%D0%BD%D0%B5%D1%82%201%20%281%29.jpg",

          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%96%D0%9A%20%D0%94%D0%BE%D0%BC%20%D0%BD%D0%B0%20%D0%94%D0%B0%D1%83%D1%80%D1%81%D0%BA%D0%BE%D0%B9/%D0%9A%D0%B0%D0%B1%D0%B8%D0%BD%D0%B5%D1%82%201/%D0%BA%D0%B0%D0%B1%D0%B8%D0%BD%D0%B5%D1%82%201%20%282%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%96%D0%9A%20%D0%94%D0%BE%D0%BC%20%D0%BD%D0%B0%20%D0%94%D0%B0%D1%83%D1%80%D1%81%D0%BA%D0%BE%D0%B9/%D0%9A%D0%B0%D0%B1%D0%B8%D0%BD%D0%B5%D1%82%201/%D0%BA%D0%B0%D0%B1%D0%B8%D0%BD%D0%B5%D1%82%201%20%283%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%96%D0%9A%20%D0%94%D0%BE%D0%BC%20%D0%BD%D0%B0%20%D0%94%D0%B0%D1%83%D1%80%D1%81%D0%BA%D0%BE%D0%B9/%D0%9A%D0%B0%D0%B1%D0%B8%D0%BD%D0%B5%D1%82%201/%D0%BA%D0%B0%D0%B1%D0%B8%D0%BD%D0%B5%D1%82%201%20%284%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%96%D0%9A%20%D0%94%D0%BE%D0%BC%20%D0%BD%D0%B0%20%D0%94%D0%B0%D1%83%D1%80%D1%81%D0%BA%D0%BE%D0%B9/%D0%9A%D0%B0%D0%B1%D0%B8%D0%BD%D0%B5%D1%82%201/%D0%BA%D0%B0%D0%B1%D0%B8%D0%BD%D0%B5%D1%82%201%20%285%29.jpg",
        ],
      },
      {
        roomName: "Постирочная",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%96%D0%9A%20%D0%94%D0%BE%D0%BC%20%D0%BD%D0%B0%20%D0%94%D0%B0%D1%83%D1%80%D1%81%D0%BA%D0%BE%D0%B9/%D0%9F%D0%BE%D1%81%D1%82%D0%B8%D1%80%D0%BE%D1%87%D0%BD%D0%B0%D1%8F/1_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%96%D0%9A%20%D0%94%D0%BE%D0%BC%20%D0%BD%D0%B0%20%D0%94%D0%B0%D1%83%D1%80%D1%81%D0%BA%D0%BE%D0%B9/%D0%9F%D0%BE%D1%81%D1%82%D0%B8%D1%80%D0%BE%D1%87%D0%BD%D0%B0%D1%8F/2_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%96%D0%9A%20%D0%94%D0%BE%D0%BC%20%D0%BD%D0%B0%20%D0%94%D0%B0%D1%83%D1%80%D1%81%D0%BA%D0%BE%D0%B9/%D0%9F%D0%BE%D1%81%D1%82%D0%B8%D1%80%D0%BE%D1%87%D0%BD%D0%B0%D1%8F/3_Post.jpg",
        ],
      },
    ],
  },
  {
    id: 21,
    title: "Дом 120м2",
    mainFoto:
      "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9D%D0%BE%D0%B2%D1%8B%D0%B5%20%D0%A1%D0%B0%D0%BB%D0%BC%D0%B0%D1%87%D0%B8/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/1_Post.jpg",
    otherFotos: [
      {
        roomName: "Гостиная",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9D%D0%BE%D0%B2%D1%8B%D0%B5%20%D0%A1%D0%B0%D0%BB%D0%BC%D0%B0%D1%87%D0%B8/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/1_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9D%D0%BE%D0%B2%D1%8B%D0%B5%20%D0%A1%D0%B0%D0%BB%D0%BC%D0%B0%D1%87%D0%B8/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/2_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9D%D0%BE%D0%B2%D1%8B%D0%B5%20%D0%A1%D0%B0%D0%BB%D0%BC%D0%B0%D1%87%D0%B8/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/3_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9D%D0%BE%D0%B2%D1%8B%D0%B5%20%D0%A1%D0%B0%D0%BB%D0%BC%D0%B0%D1%87%D0%B8/%D0%93%D0%BE%D1%81%D1%82%D0%B8%D0%BD%D0%B0%D1%8F/4_Post.jpg",
        ],
      },
      {
        roomName: "Спальня",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9D%D0%BE%D0%B2%D1%8B%D0%B5%20%D0%A1%D0%B0%D0%BB%D0%BC%D0%B0%D1%87%D0%B8/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F/1_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9D%D0%BE%D0%B2%D1%8B%D0%B5%20%D0%A1%D0%B0%D0%BB%D0%BC%D0%B0%D1%87%D0%B8/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F/2_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9D%D0%BE%D0%B2%D1%8B%D0%B5%20%D0%A1%D0%B0%D0%BB%D0%BC%D0%B0%D1%87%D0%B8/%D0%A1%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F/3_Post.jpg",
        ],
      },
      {
        roomName: "Детская мальчик",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9D%D0%BE%D0%B2%D1%8B%D0%B5%20%D0%A1%D0%B0%D0%BB%D0%BC%D0%B0%D1%87%D0%B8/%D0%9C%D0%B0%D1%82%D0%B2%D0%B5%D0%B9/1_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9D%D0%BE%D0%B2%D1%8B%D0%B5%20%D0%A1%D0%B0%D0%BB%D0%BC%D0%B0%D1%87%D0%B8/%D0%9C%D0%B0%D1%82%D0%B2%D0%B5%D0%B9/2_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9D%D0%BE%D0%B2%D1%8B%D0%B5%20%D0%A1%D0%B0%D0%BB%D0%BC%D0%B0%D1%87%D0%B8/%D0%9C%D0%B0%D1%82%D0%B2%D0%B5%D0%B9/3_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9D%D0%BE%D0%B2%D1%8B%D0%B5%20%D0%A1%D0%B0%D0%BB%D0%BC%D0%B0%D1%87%D0%B8/%D0%9C%D0%B0%D1%82%D0%B2%D0%B5%D0%B9/4_Post.jpg",
        ],
      },

      {
        roomName: "Детская девочка",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9D%D0%BE%D0%B2%D1%8B%D0%B5%20%D0%A1%D0%B0%D0%BB%D0%BC%D0%B0%D1%87%D0%B8/%D0%90%D0%BC%D0%B5%D0%BB%D0%B8%D1%8F/1_Post.jpg",

          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9D%D0%BE%D0%B2%D1%8B%D0%B5%20%D0%A1%D0%B0%D0%BB%D0%BC%D0%B0%D1%87%D0%B8/%D0%90%D0%BC%D0%B5%D0%BB%D0%B8%D1%8F/2_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9D%D0%BE%D0%B2%D1%8B%D0%B5%20%D0%A1%D0%B0%D0%BB%D0%BC%D0%B0%D1%87%D0%B8/%D0%90%D0%BC%D0%B5%D0%BB%D0%B8%D1%8F/3_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9D%D0%BE%D0%B2%D1%8B%D0%B5%20%D0%A1%D0%B0%D0%BB%D0%BC%D0%B0%D1%87%D0%B8/%D0%90%D0%BC%D0%B5%D0%BB%D0%B8%D1%8F/4_Post.jpg",
        ],
      },

      {
        roomName: "Холл",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9D%D0%BE%D0%B2%D1%8B%D0%B5%20%D0%A1%D0%B0%D0%BB%D0%BC%D0%B0%D1%87%D0%B8/%D0%A5%D0%BE%D0%BB%D0%BB/1_Post.jpg",

          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9D%D0%BE%D0%B2%D1%8B%D0%B5%20%D0%A1%D0%B0%D0%BB%D0%BC%D0%B0%D1%87%D0%B8/%D0%A5%D0%BE%D0%BB%D0%BB/2_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9D%D0%BE%D0%B2%D1%8B%D0%B5%20%D0%A1%D0%B0%D0%BB%D0%BC%D0%B0%D1%87%D0%B8/%D0%A5%D0%BE%D0%BB%D0%BB/3_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9D%D0%BE%D0%B2%D1%8B%D0%B5%20%D0%A1%D0%B0%D0%BB%D0%BC%D0%B0%D1%87%D0%B8/%D0%A5%D0%BE%D0%BB%D0%BB/4_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9D%D0%BE%D0%B2%D1%8B%D0%B5%20%D0%A1%D0%B0%D0%BB%D0%BC%D0%B0%D1%87%D0%B8/%D0%A5%D0%BE%D0%BB%D0%BB/5_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9D%D0%BE%D0%B2%D1%8B%D0%B5%20%D0%A1%D0%B0%D0%BB%D0%BC%D0%B0%D1%87%D0%B8/%D0%A5%D0%BE%D0%BB%D0%BB/6_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9D%D0%BE%D0%B2%D1%8B%D0%B5%20%D0%A1%D0%B0%D0%BB%D0%BC%D0%B0%D1%87%D0%B8/%D0%A5%D0%BE%D0%BB%D0%BB/7_Post.jpg",
        ],
      },
      {
        roomName: "СУ",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9D%D0%BE%D0%B2%D1%8B%D0%B5%20%D0%A1%D0%B0%D0%BB%D0%BC%D0%B0%D1%87%D0%B8/%D0%A1%D0%B0%D0%BD%D1%83%D0%B7%D0%B5%D0%BB2/1_Post.jpg",

          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9D%D0%BE%D0%B2%D1%8B%D0%B5%20%D0%A1%D0%B0%D0%BB%D0%BC%D0%B0%D1%87%D0%B8/%D0%A1%D0%B0%D0%BD%D1%83%D0%B7%D0%B5%D0%BB2/2_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9D%D0%BE%D0%B2%D1%8B%D0%B5%20%D0%A1%D0%B0%D0%BB%D0%BC%D0%B0%D1%87%D0%B8/%D0%A1%D0%B0%D0%BD%D1%83%D0%B7%D0%B5%D0%BB2/3_Post.jpg",
        ],
      },
      {
        roomName: "Гостевая",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9D%D0%BE%D0%B2%D1%8B%D0%B5%20%D0%A1%D0%B0%D0%BB%D0%BC%D0%B0%D1%87%D0%B8/%D0%93%D0%BE%D1%81%D1%82%D0%B5%D0%B2%D0%B0%D1%8F/1_Post.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%9D%D0%BE%D0%B2%D1%8B%D0%B5%20%D0%A1%D0%B0%D0%BB%D0%BC%D0%B0%D1%87%D0%B8/%D0%93%D0%BE%D1%81%D1%82%D0%B5%D0%B2%D0%B0%D1%8F/2_Post.jpg",
        ],
      },
    ],
  },

  {
    id: 22,
    title: "Спальня 25м2",
    mainFoto:
      "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D1%81%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F%20%D0%92%D0%BE%D0%B7%D0%BD%D0%B5%D1%81%D0%B5%D0%BD%D0%B8%D0%B5/4_Post%20%281%29.jpg",
    otherFotos: [
      {
        roomName: "Спальня",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D1%81%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F%20%D0%92%D0%BE%D0%B7%D0%BD%D0%B5%D1%81%D0%B5%D0%BD%D0%B8%D0%B5/1_Post%20%284%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D1%81%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F%20%D0%92%D0%BE%D0%B7%D0%BD%D0%B5%D1%81%D0%B5%D0%BD%D0%B8%D0%B5/2_Post%20%284%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D1%81%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F%20%D0%92%D0%BE%D0%B7%D0%BD%D0%B5%D1%81%D0%B5%D0%BD%D0%B8%D0%B5/3_Post%20%283%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D1%81%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F%20%D0%92%D0%BE%D0%B7%D0%BD%D0%B5%D1%81%D0%B5%D0%BD%D0%B8%D0%B5/4_Post%20%281%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D1%81%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F%20%D0%92%D0%BE%D0%B7%D0%BD%D0%B5%D1%81%D0%B5%D0%BD%D0%B8%D0%B5/5_Post%20%282%29.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D1%81%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F%20%D0%92%D0%BE%D0%B7%D0%BD%D0%B5%D1%81%D0%B5%D0%BD%D0%B8%D0%B5/123.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D1%81%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F%20%D0%92%D0%BE%D0%B7%D0%BD%D0%B5%D1%81%D0%B5%D0%BD%D0%B8%D0%B5/60000_Post.jpg",
        ],
      },
    ],
  },
  {
    id: 23,
    title: "Детская 25м2",
    mainFoto:
      "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%B4%D0%B5%D1%82%D1%81%D0%BA%D0%B0%D1%8F%20%D0%92%D0%BE%D0%B7%D0%BD%D0%B5%D1%81%D0%B5%D0%BD%D0%B8%D0%B5/5.jpg",
    otherFotos: [
      {
        roomName: "Детская",
        photos: [
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%B4%D0%B5%D1%82%D1%81%D0%BA%D0%B0%D1%8F%20%D0%92%D0%BE%D0%B7%D0%BD%D0%B5%D1%81%D0%B5%D0%BD%D0%B8%D0%B5/1.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%B4%D0%B5%D1%82%D1%81%D0%BA%D0%B0%D1%8F%20%D0%92%D0%BE%D0%B7%D0%BD%D0%B5%D1%81%D0%B5%D0%BD%D0%B8%D0%B5/2.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%B4%D0%B5%D1%82%D1%81%D0%BA%D0%B0%D1%8F%20%D0%92%D0%BE%D0%B7%D0%BD%D0%B5%D1%81%D0%B5%D0%BD%D0%B8%D0%B5/3.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%B4%D0%B5%D1%82%D1%81%D0%BA%D0%B0%D1%8F%20%D0%92%D0%BE%D0%B7%D0%BD%D0%B5%D1%81%D0%B5%D0%BD%D0%B8%D0%B5/4.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%B4%D0%B5%D1%82%D1%81%D0%BA%D0%B0%D1%8F%20%D0%92%D0%BE%D0%B7%D0%BD%D0%B5%D1%81%D0%B5%D0%BD%D0%B8%D0%B5/5.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%B4%D0%B5%D1%82%D1%81%D0%BA%D0%B0%D1%8F%20%D0%92%D0%BE%D0%B7%D0%BD%D0%B5%D1%81%D0%B5%D0%BD%D0%B8%D0%B5/6.jpg",
          "https://thumb.cloud.mail.ru/weblink/thumb/xw1/nKoY/moiZVdug3/%D0%A0%D0%B5%D0%BD%D0%B4%D0%B5%D1%80%D1%8B%20%D0%B4%D0%B5%D1%82%D1%81%D0%BA%D0%B0%D1%8F%20%D0%92%D0%BE%D0%B7%D0%BD%D0%B5%D1%81%D0%B5%D0%BD%D0%B8%D0%B5/7.jpg",
        ],
      },
    ],
  },
];
